import { useState } from "react";
import SlideOverPanel from "../../../../components/SlideOverPanel";
import { Fixture } from "../../../../types/fixture";
import PrizeDistributionSelector from "./PrizeDistributionSelector";
import EditDataRow from "../../../../components/EditDataRow";
import TVChannelSelector from "./TvChannelSelector";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../components/Main/Button";
import { XCircleIcon } from "@heroicons/react/outline";
import { useMutation, useQueryClient } from "react-query";
import { updateFixturePrizeDistribution } from "../../../../app/fixtures";
import fanslideClient from "../../../../app/api/FanslideClient";
import ContestSelector from "./ContestSelector";

const InfoTab = ({ fixture }: { fixture?: Fixture }) => {
  const [tvChannelSelectorOpen, openTVChannelSelector] = useState(false);
  const [prizeDistSelectorOpen, openPrizeDistSelector] = useState(false);
  const [contestSelectorOpen, openContestSelector] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (contestTypeId: string) => {
      const result = await fanslideClient.addRemoveFixtureContestId(
        fixture?.id || "",
        contestTypeId
      );
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
      },
    }
  );

  const navigate = useNavigate();
  if (!fixture) return null;

  return (
    <>
      <div className="divide-y">
        <EditDataRow
          title="Kick Off"
          value={moment(fixture.startTime).format("ddd, MMMM Do, HH:mm")}
        />
        <EditDataRow title="Competition" value={fixture?.competition?.name} />
        <EditDataRow
          title="Contests"
          onClick={() => {
            openContestSelector(true);
          }}
          onClickText="Add Contest"
        >
          <div>
            {fixture?.fixtureContestTypes?.map((contest) => (
              <div className="flex gap-2 items-center">
                <button
                  type="button"
                  title="Logout"
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700"
                  onClick={() => {
                    if (
                      window.confirm(
                        `are you sure you want to remove ${contest} from ${fixture.description}?`
                      )
                    ) {
                      mutation.mutate(contest);
                    }
                  }}
                >
                  <XCircleIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                    aria-label="logout"
                  />
                  {/* Log out */}
                </button>
                <p
                  className="cursor-pointer relative"
                  onClick={() => navigate(`contests/${contest}`)}
                >
                  {contest}
                </p>
              </div>
            ))}
          </div>
        </EditDataRow>
        <EditDataRow
          title="TV Channel"
          value={fixture.tvChannel ?? "Not Selected"}
          onClick={() => {
            openTVChannelSelector(true);
          }}
        />
      </div>
      <SlideOverPanel
        open={tvChannelSelectorOpen}
        setOpen={openTVChannelSelector}
        content={
          <TVChannelSelector
            fixture={fixture}
            onComplete={() => openTVChannelSelector(false)}
          />
        }
      />
      <SlideOverPanel
        open={prizeDistSelectorOpen}
        setOpen={openPrizeDistSelector}
        content={
          <PrizeDistributionSelector
            fixture={fixture}
            onComplete={() => openPrizeDistSelector(false)}
          />
        }
      />
      <SlideOverPanel
        open={contestSelectorOpen}
        setOpen={openContestSelector}
        content={
          <ContestSelector
            fixture={fixture}
            onComplete={() => openContestSelector(false)}
          />
        }
      />
    </>
  );
};

export default InfoTab;
