import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { twMerge } from "tailwind-merge";
import fanslideClient from "../../../../app/api/FanslideClient";
import { PrimaryButton } from "../../../../components/Main/Button";
import Loading from "../../../../components/Main/Loading";
import useGetData from "../../../../hooks/useGetData";
import { Fixture, TvChannel } from "../../../../types/fixture";

const TVChannelSelector = ({
  fixture,
  onComplete,
}: {
  fixture?: Fixture;
  onComplete: () => void;
}) => {
  const { isLoading, data, error } =
    useGetData<TvChannel[]>("/admin/tv-channels");

  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<TvChannel | undefined>(
    data?.find((c) => c.name === fixture?.tvChannel)
  );

  const mutation = useMutation(
    //TODO: use v2 api here
    () =>
      fanslideClient.updateFixtures({
        updateObject: {
          tvChannel: selected?.name,
        },
        fixturesIds: [fixture?.id ?? ""],
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
        onComplete();
      },
    }
  );

  if (!fixture) return null;
  if (isLoading) return <Loading />;

  return (
    <div className="w-full">
      <p className="text-lg font-bold mb-8">Select TV Channel:</p>
      <div className="flex flex-col gap-y-2 items-start">
        {data?.map((channel) => (
          <button
            key={channel.id}
            className={twMerge(
              "block",
              selected?.id === channel.id && "font-bold"
            )}
            onClick={() => setSelected(channel)}
          >
            {channel.name}
          </button>
        ))}
      </div>
      <div className="mt-8 items-start">
        <PrimaryButton
          text="Choose"
          onClick={() => mutation.mutate()}
          disabled={!selected}
        />
      </div>
    </div>
  );
};

export default TVChannelSelector;
