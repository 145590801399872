import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import Layout from "../../../components/Layout";
import Tabs from "../../../components/Tabs";
import { Fixture } from "../../../types/fixture";
import { Tab } from "../../../types";
import { getFixtureAwayAndHomeTeams } from "../../../app/fixtures";
import ActivateButton from "./ActivateButton";
import Header from "./Header";
import InfoTab from "./InfoTab";
import ContestsTab from "./ContestsTab";
import TeamTab from "./TeamTab";
import KitsTab from "./KitsTab";

export default function SelectedFixture() {
  const { id } = useParams();
  const { hash } = useLocation();
  const navigate = useNavigate();

  if (!hash) navigate("#info");

  const query = useGetData<Fixture>(`/admin/fixtures/${id}`);

  const { isLoading, data: fixture } = query;

  const { homeTeam, awayTeam } = getFixtureAwayAndHomeTeams(fixture);

  const tabs: Tab[] = [];

  if (fixture?.id) {
    tabs.push({
      name: "Info",
      href: "#info",
      component: <InfoTab fixture={fixture} />,
    });
    tabs.push({
      name: "Contests",
      href: "#contests",
      component: <ContestsTab fixture={fixture} />,
    });
    tabs.push({
      name: "Kits",
      href: "#kits",
      component: <KitsTab fixture={fixture} />,
    });
  }

  if (homeTeam) {
    tabs.push({
      name: `${homeTeam?.shortName} Players`,
      href: "#home-team",
      component: (
        <TeamTab
          key={"home-team-tab"}
          fixture={fixture}
          team={homeTeam}
          teamType="home"
        />
      ),
    });
  }

  if (awayTeam) {
    tabs.push({
      name: `${awayTeam?.shortName} Players`,
      href: "#away-team",
      component: (
        <TeamTab
          key={"away-team-tab"}
          fixture={fixture}
          team={awayTeam}
          teamType="away"
        />
      ),
    });
  }

  const currentTab = tabs.find((tab) => tab.href === hash);

  return (
    <Layout query={query} handleLoading>
      <main className="w-5/6 mx-auto">
        <Header
          title={fixture?.description}
          buttons={[<ActivateButton fixture={fixture} />]}
        />
        <Tabs tabs={tabs} currentTab={currentTab} />
        {currentTab?.component}
      </main>
    </Layout>
  );
}
