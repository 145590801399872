import * as React from "react";
import { twMerge } from "tailwind-merge";

export interface ITdProps {
  children?: React.ReactNode;
  className?: string;
}

export default function Td({ children, className }: ITdProps) {
  return (
    <td
      className={twMerge(
        "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        className
      )}
    >
      {children}
    </td>
  );
}
