import React, { useEffect, useState } from "react";
import { ServerFeedback } from "../../hooks/useServerFeedback";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/solid";

function DisplayServerFeedback({
  feedback: { type, message },
}: {
  feedback: ServerFeedback;
}) {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(false);
  }, [message, type]);

  if (hide) return <></>;

  if (type && message) {
    const color = type === "success" ? "green" : "red";
    const Icon = () => {
      if (type === "success") {
        return (
          <CheckCircleIcon
            className="h-5 w-100 text-green-400"
            aria-hidden="true"
          />
        );
      }
      return (
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      );
    };
    return (
      <div
        className={`rounded-md bg-${color}-50 p-4 border-${color}-200 border my-4`}
      >
        <div className="flex">
          <div className="flex-shrink-0" onClick={() => setHide(true)}>
            <Icon />
          </div>
          <div className="ml-3">
            {message?.split("\n").map((s) => {
              if (!s) return <></>;
              return (
                <h3 className={`text-sm font-medium text-${color}-800 block`}>
                  {s}
                </h3>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}

export default DisplayServerFeedback;
