import { Fixture } from "../../../../types/fixture";
import useGetData from "../../../../hooks/useGetData";
import { Contests } from "../../../../types/contests";
import Table from "../../../../components/Table";
import Td from "../../../../components/Td";
import Tr from "../../../../components/Tr";
import { useNavigate } from "react-router";

const ContestsTab = ({ fixture }: { fixture?: Fixture }) => {
  const {
    isLoading,
    error,
    data: contests,
  } = useGetData<Contests[]>(
    `/admin/fixtures/${fixture?.id}/contests`,
    undefined,
    { enabled: !!fixture?.id }
  );
  const navigate = useNavigate();

  return (
    <>
      <Table
        columns={["ID", "Name", "Prize Distribution"]}
        title="Contests"
        showSearch={false}
        loading={isLoading}
      >
        {contests?.map((contest, i) => (
          <Tr
            key={contest.contestId}
            index={i}
            onClick={
              contest.contestId.includes("MONEY+")
                ? () => navigate(`contests/${contest.contestId}`)
                : undefined
            }
          >
            <Td>{contest.contestId}</Td>
            <Td>{contest.name}</Td>
            <Td>{contest.prizeDistributionId}</Td>
          </Tr>
        ))}
      </Table>
    </>
  );
};

export default ContestsTab;
