import moment from "moment";
import React from "react";
import EditDataRow from "../../../components/EditDataRow";
import useGetData from "../../../hooks/useGetData";
import { User } from "../../../types/user";

type Props = {
  user?: User;
};

const InfoTab = ({ user }: Props) => {
  const userBalanceQuery = useGetData<{ amount: number }>(`/${user?.id}/balance`);
  const { data: userBalance } = userBalanceQuery;

  if (!user) return null;
  return (
    <div className="divide-y">
      <EditDataRow title="ID" value={user.id} />
      <EditDataRow title="Username" value={user.fanslideUsername} />
      <EditDataRow title="Signup Date" value={moment(user.createdAt).format("DD/MM/YYYY HH:mm")} />
      <EditDataRow title="email" value={user.email} />
      <EditDataRow title="Balance" value={userBalance?.amount} />
      <EditDataRow title="Games Played" value={user.gamesPlayed} />
      <EditDataRow title="Highest Score" value={user.highestScore} />
      <EditDataRow title="Number of Wins" value={user.numberOfWins} />
      <EditDataRow title="Average Slides" value={user.avgSlides} />
      <EditDataRow title="Fanslide Rating" value={user.fanslideRating} />
      <EditDataRow title="PFGs" value={user.pfgs} />
      <EditDataRow title="App Version" value={user.appVersion} />
      <EditDataRow title="Notifications" value={user.notificationToken ? "Yes" : "No"} />
      <EditDataRow title="Groups">
        <div>
          {user.groups?.map((group) => (
            <dd className="flex text-sm text-gray-900 ">{group.title}</dd>
          ))}
        </div>
      </EditDataRow>
    </div>
  );
};

export default InfoTab;
