import Table from "../../components/Table";
import Tr from "../../components/Tr";
import Td from "../../components/Td";
import useGetData from "../../hooks/useGetData";
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { ContestTypeT } from "../../types/contests";

export default function ContestTypesPage() {
  const query = useGetData<ContestTypeT[]>("/admin/contest-types");
  const { data, isLoading } = query;
  const navigate = useNavigate();

  return (
    <Layout query={query}>
      <Table
        title="Contest Types"
        columns={[
          "ID",
          "Name",
          "Pay Outs",
          "Players",
          "Winner",
          "Structure",
          "Entry Fee",
          "icon",
        ]}
        loading={isLoading}
        className="mx-8"
        buttons={[
          {
            text: "Add Contest Type",
            onClick: () => navigate("/contest-types/new"),
          },
        ]}
      >
        {data?.map((ct) => (
          <Tr
            key={ct.contestTypeId}
            onClick={() => navigate("/contest-types/" + ct.contestTypeId)}
          >
            <Td>{ct.contestTypeId}</Td>
            <Td>{ct.textContestName}</Td>
            <Td>{ct.textPayouts}</Td>
            <Td>{ct.textPlayers}</Td>
            <Td>{ct.textWinner}</Td>
            <Td>{ct.contestStructure}</Td>
            <Td>{ct.entry}</Td>
            <Td>
              <img
                src={ct.contestIcon}
                alt={ct.textContestName}
                style={{ width: 35 }}
              />
            </Td>
          </Tr>
        ))}
      </Table>
    </Layout>
  );
}
