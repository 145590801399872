import { useAllKitsContext } from "../../contexts/AllKitsContext";
import { NewKitForm } from "./KitForm";
import useGetData from "../../hooks/useGetData";
import { Kit } from "../../types/kit";
import Layout from "../../components/Layout";
import KitsTable from "./KitsTable";

export default function Kits() {
  const query = useGetData<Kit[]>("/admin/kits");
  const { isLoading, data, error } = query;
  const allKitsCtx = useAllKitsContext();
  return (
    <Layout query={query}>
      {allKitsCtx.showing === "table" && (
        <KitsTable
          buttons={[
            {
              text: "Create Kit",
              onClick: () => {
                allKitsCtx.setKitToEdit(undefined);
                allKitsCtx.changeShowing("form");
              },
            },
          ]}
          isLoading={isLoading}
          data={data}
          onClickRow={(kit) => {
            allKitsCtx.setKitToEdit(kit);
            allKitsCtx.changeShowing("form");
          }}
        />
      )}
      {allKitsCtx.showing === "form" && (
        <NewKitForm
          kitToEdit={allKitsCtx.kitToEdit}
          cancelForm={() => {
            allKitsCtx.setKitToEdit(undefined);
            allKitsCtx.changeShowing("table");
          }}
          createKit={allKitsCtx.createKit}
          updateKit={allKitsCtx.updateKit}
        />
      )}
    </Layout>
  );
}
