import { useUsersContext } from "../../contexts/UsersContext";
import Debug from "../../components/Main/Debug";
import Table from "../../components/Table";
import Tr from "../../components/Tr";
import Td from "../../components/Td";
import moment from "moment";
import useGetData from "../../hooks/useGetData";

import { User } from "../../types/user";
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";

export default function UsersPage() {
  const navigate = useNavigate();
  const query = useGetData<User[]>("/search/users");
  const { data, isLoading } = query;

  return (
    <Layout query={query}>
      <Table
        title="Users"
        columns={[
          "Name",
          "Fanslide Username",
          "Email",
          "Created At",
          "National Team",
        ]}
        loading={isLoading}
        className="mx-8"
      >
        {data?.map((user) => (
          <Tr key={user.id} onClick={() => navigate("/users/" + user.id)}>
            <Td>{user.firstName + " " + user.lastName}</Td>
            <Td>{user.fanslideUsername}</Td>
            <Td>{user.email}</Td>
            <Td>{moment(user.createdAt).format("ddd, MMMM Do, HH:mm")}</Td>
            <Td>{user.nationalTeam}</Td>
          </Tr>
        ))}
      </Table>
    </Layout>
  );
}
