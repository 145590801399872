import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import fanslideClient from "../../../app/api/FanslideClient";
import EditDataRow from "../../../components/EditDataRow";
import Input from "../../../components/Input";
import Layout from "../../../components/Layout";
import { PrimaryButton } from "../../../components/Main/Button";
import useGetData from "../../../hooks/useGetData";
import { ContestTypeT } from "../../../types/contests";
import Header from "../../Fixtures/SelectedFixture/Header";
import DistributionGrid from "./DistributionGrid";
import Section from "./Section";

const SelectedContestType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const query = useGetData<Partial<ContestTypeT>>(
    `/admin/contest-types/${id}`,
    undefined,
    { enabled: id !== "new" }
  );

  let { data: contestType, isLoading } = query || {};
  if (id === "new") {
    contestType = {};
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    control,
  } = useForm({ defaultValues: { ...contestType } });
  const watchStructure = watch("contestStructure");

  const queryClient = useQueryClient();
  const mutation = useMutation(
    //TODO: use v2 api here
    (data: ContestTypeT) => {
      if (id === "new") {
        return fanslideClient.createContestType(data);
      } else {
        return fanslideClient.updateContestType(id!, data);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/contest-types`);
        queryClient.invalidateQueries(`/admin/contest-types/${id}`);
        navigate("/contest-types");
      },
    }
  );
  const deleteMutation = useMutation(
    //TODO: use v2 api here
    () => fanslideClient.deleteContestType(id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/contest-types`);
        queryClient.invalidateQueries(`/admin/contest-types/${id}`);
        navigate("/contest-types");
      },
    }
  );
  const onSubmit = (values: ContestTypeT) => {
    mutation.mutate(values);
  };

  useEffect(() => {
    if (contestType && Object.keys(contestType).length > 0) {
      Object.keys(contestType).forEach((key) => {
        setValue(
          key as keyof typeof contestType,
          contestType![key as keyof typeof contestType]
        );
      });
    }
  }, [contestType, setValue]);

  console.log(errors);

  const buttons = [
    <PrimaryButton text="Submit" type="submit" className="px-8" />,
  ];
  if (id !== "new") {
    buttons.unshift(
      <PrimaryButton
        text="Delete"
        color="red"
        onClick={async () => {
          const accept = await window.confirm(
            `Are you sure you want to delete the contest type\n"${contestType?.contestTypeId}"?`
          );
          if (accept) {
            deleteMutation.mutate();
          }
        }}
        className="px-8"
      />
    );
  }
  return (
    <Layout query={id === "new" ? undefined : query} handleLoading>
      {/* @ts-expect-error */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-6 mb-8">
          <Header
            title={
              id === "new"
                ? "Create new contest type"
                : `Edit contest type: ${id}`
            }
            buttons={buttons}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <Section title="Details">
            <EditDataRow title="Choose an ID:">
              <Input {...register("contestTypeId", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Icon URL:">
              <Input {...register("contestIcon", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Default:">
              <input type="checkbox" {...register("defaultContest")} />
            </EditDataRow>
            <EditDataRow title="Auto Select:">
              <input type="checkbox" {...register("autoSelect")} />
            </EditDataRow>
            <EditDataRow title="Name:">
              <Input {...register("textContestName", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Entry Fee:">
              <Input {...register("entry", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Sort:">
              <Input {...register("sortOrder", { required: true })} />
            </EditDataRow>
          </Section>
          <Section title="Display Text">
            <EditDataRow title="Type:">
              <Input {...register("textContestType", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Entry Fee:">
              <Input {...register("textEntryFee", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Pay Outs:">
              <Input {...register("textPayouts", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Players:">
              <Input {...register("textPlayers", { required: true })} />
            </EditDataRow>
            <EditDataRow title="Winner:">
              <Input {...register("textWinner", { required: true })} />
            </EditDataRow>
          </Section>
          <Section title="Configuration" className="md:col-span-1 mt-8">
            <EditDataRow title="Type:">
              <select
                className="w-full"
                {...register("contestStructure", { required: true })}
              >
                <option value="">Choose...</option>
                <option value="Unlimited">Unlimited</option>

                <option value="Fixed">Fixed</option>
                <option value="UnlimitedDoubleUp">UnlimitedDoubleUp</option>
                <option value="FixedDoubleUp">FixedDoubleUp</option>
              </select>
            </EditDataRow>
            {watchStructure === "Fixed" || watchStructure === "Unlimited" ? (
              <Controller
                name="distribution"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <DistributionGrid
                    initData={value}
                    onChange={(data) => onChange(JSON.stringify(data))}
                    error={error}
                  />
                )}
              />
            ) : null}
            {watchStructure === "Unlimited" ? (
              <EditDataRow title="Commission:">
                <Input
                  {...register("fanslideCommission", { required: true })}
                />
              </EditDataRow>
            ) : null}
            {watchStructure === "Fixed" ? (
              <>
                <EditDataRow title="Min Entrants:">
                  <Input {...register("minEntrants", { required: true })} />
                </EditDataRow>
                <EditDataRow title="Max Entrants:">
                  <Input {...register("maxEntrants", { required: true })} />
                </EditDataRow>
              </>
            ) : null}
            {watchStructure === "UnlimitedDoubleUp" ? (
              <>
                <EditDataRow title="Payout Percentage:">
                  <Input
                    {...register("prizePayoutPercentage", { required: true })}
                  />
                </EditDataRow>
              </>
            ) : null}
            {watchStructure === "FixedDoubleUp" ? (
              <>
                <EditDataRow title="Payout Positions:">
                  <Input
                    {...register("prizePayoutPositions", { required: true })}
                  />
                </EditDataRow>
              </>
            ) : null}
          </Section>
        </div>
      </form>
    </Layout>
  );
};

export default SelectedContestType;
