import React from "react";
import { useActiveFixturesContext } from "../../contexts/ActiveFixturesContext";
import NiceBox from "../Main/NiceBox";
import Select from "react-select";
import useServerFeedback from "../../hooks/useServerFeedback";
import DisplayServerFeedback from "../Main/DisplayServerFeedback";

type FormToSelect = "changePrizeDistribution" | "changeTvChannel" | "";

export default function FixturesBulkActionSheet() {
  const fixturesCtx = useActiveFixturesContext();
  const [selectedForm, setSelectedForm] = React.useState<FormToSelect>("");

  return (
    <NiceBox>
      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">{fixturesCtx.selectedFixtures.length} Fixtures selected</p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <button
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => {
              setSelectedForm("");
              fixturesCtx.clearFixtureSelection();
            }}
          >
            clear selection
          </button>

          <button
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => setSelectedForm("changeTvChannel")}
          >
            Change TV channel
          </button>
          <button
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => setSelectedForm("changePrizeDistribution")}
          >
            Change prize distribution
          </button>
        </div>
      </nav>

      <div
        //   className="flex items-center w-100"
        className="bg-white px-4 py-3 block items-center justify-between border-t border-gray-200 sm:px-6"
      >
        {selectedForm === "changePrizeDistribution" && <SelectPrizeDistributionForm />}
        {selectedForm === "changeTvChannel" && <SelectTvChannelForm />}
      </div>
    </NiceBox>
  );
}

function SelectPrizeDistributionForm() {
  const fixturesCtx = useActiveFixturesContext();
  const [selectedPrizeId, setSelectedPrizeId] = React.useState("");
  const { feedback, setFeedback } = useServerFeedback();

  const options: { value: string; label: string }[] = [];

  fixturesCtx.prizeDistributions.forEach((d) => {
    if (d?.id) {
      options.push({ value: d.id, label: d.name });
    }
  });

  const save = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const fixturesIds = fixturesCtx.selectedFixtures.map((f) => f.id);

    console.log({
      fixturesIds,
    });
    const res = await fixturesCtx.updatePrizeDistribution({
      prizeDistributionId: selectedPrizeId,
      fixturesIds,
    });
  };

  return (
    <form className="block w-full">
      <div className=" inline-block w-25">Select new Prize distribution</div>
      <Select
        className="basic-single inline-block w-50"
        classNamePrefix="select"
        defaultValue={null}
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name="prize"
        options={options}
        styles={{}}
        onChange={(e) => {
          setSelectedPrizeId(e?.value || "");
        }}
      />
      <div className="inline-block w-20 ml-5 ">
        <button
          onClick={(e) => {
            save(e);
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save
        </button>
      </div>
      <div className="block">
        <DisplayServerFeedback feedback={feedback} />
      </div>
    </form>
  );
}
function SelectTvChannelForm() {
  const fixturesCtx = useActiveFixturesContext();
  const [selectedChannel, setSelectedCannel] = React.useState("");
  const { feedback, setFeedback } = useServerFeedback();

  const options: { value: string; label: string }[] = [];

  fixturesCtx.tvChannels.forEach((d) => {
    if (d?.id) {
      options.push({ value: d.id, label: d.name });
    }
  });

  const save = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const fixturesIds = fixturesCtx.selectedFixtures.map((f) => f.id);

    console.log({
      fixturesIds,
    });
    const res = await fixturesCtx.updateTvChannel({
      tvChannel: selectedChannel,
      fixturesIds,
    });
  };

  return (
    <form className="block w-full">
      <div className=" inline-block w-25">Select new TV channel</div>
      <Select
        className="basic-single inline-block w-50"
        classNamePrefix="select"
        defaultValue={null}
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name="prize"
        options={options}
        styles={{}}
        onChange={(e) => {
          setSelectedCannel(e?.label || "");
        }}
      />
      <div className="inline-block w-20 ml-5 ">
        <button
          onClick={(e) => {
            save(e);
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save
        </button>
      </div>
      <div className="block">
        <DisplayServerFeedback feedback={feedback} />
      </div>
    </form>
  );
}
