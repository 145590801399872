import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Modal from "react-modal";
import Kits from "./pages/Kits";
import { AllKitsContextProvider } from "./contexts/AllKitsContext";
import { AllFixturesContextProvider } from "./contexts/FixturesContext";
import Fixtures from "./pages/Fixtures";
import { ActiveFixturesContextProvider } from "./contexts/ActiveFixturesContext";
import { PlayersContextProvider } from "./contexts/PlayersContext";
import Players from "./pages/Players";
import Home from "./pages/Home";
import { UsersContextProvider } from "./contexts/UsersContext";
import UsersPage from "./pages/Users";
import { TeamsContextProvider } from "./contexts/TeamsContext";
import TeamsPage from "./pages/Teams";
import SelectedTeam from "./pages/Teams/SelectedTeam";
import SelectedPlayer from "./pages/Players/SelectedPlayer";
import SelectedFixture from "./pages/Fixtures/SelectedFixture";
import SelectedContest from "./pages/Fixtures/SelectedFixture/ContestsTab/SelectedContest";
import SelectedUser from "./pages/Users/SelectedUser";
import ContestTypesPage from "./pages/ContestTypes";
import SelectedContestType from "./pages/ContestTypes/SelectedContestType";

export const fsQueryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: 15 * 60 * 1000, // 15 minutes
      cacheTime: 15 * 60 * 1000, // 15 minutes
      retry: 0,
      retryDelay: 1 * 60 * 1000, // 1 minute
    },
  },
});

Modal.setAppElement("#root");

export default function Router() {
  return (
    <QueryClientProvider client={fsQueryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ActiveFixturesContextProvider>
                <Home />
              </ActiveFixturesContextProvider>
            }
          />
          <Route
            path="/fixtures"
            element={
              <AllFixturesContextProvider>
                <Fixtures />
              </AllFixturesContextProvider>
            }
          />
          <Route path="/fixtures/:id" element={<SelectedFixture />} />
          <Route
            path="/fixtures/:id/contests/:contestTypeId"
            element={<SelectedContest />}
          />
          <Route
            path="/teams"
            element={
              <TeamsContextProvider>
                <TeamsPage />
              </TeamsContextProvider>
            }
          />
          <Route
            path="/teams/:id"
            element={
              <TeamsContextProvider>
                <SelectedTeam />
              </TeamsContextProvider>
            }
          />
          <Route
            path="/players"
            element={
              <PlayersContextProvider>
                <Players />
              </PlayersContextProvider>
            }
          />
          <Route path="/players/:id" element={<SelectedPlayer />} />
          <Route
            path="/kits"
            element={
              <AllKitsContextProvider>
                <Kits />
              </AllKitsContextProvider>
            }
          />
          <Route
            path="/users"
            element={
              <UsersContextProvider>
                <UsersPage />
              </UsersContextProvider>
            }
          />
          <Route path="/users/:id" element={<SelectedUser />} />

          <Route path="/contest-types" element={<ContestTypesPage />} />
          <Route path="/contest-types/:id" element={<SelectedContestType />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
