import moment from "moment";
import React, { useEffect } from "react";
import Table from "../../../components/Table";
import Td from "../../../components/Td";
import Tr from "../../../components/Tr";
import useGetData from "../../../hooks/useGetData";
import { User } from "../../../types/user";

type Props = {
  user?: User;
};

type GameHistoryItem = {
  description: string;
  timestamp: string;
  points: string;
  globalWinner: string;
  globalPosition: string;
  globalTotal: string;
  rating: string;
  nbPositiveEvents: string;
  totalPositivePoints: string;
  nbNegativeEvents: string;
  totalNegativePoints: string;
};

const GameHistoryTab = ({ user }: Props) => {
  const {
    data: gameHistory,
    isLoading,
    error,
  } = useGetData<GameHistoryItem[]>(`/admin/users/${user?.id}/game-history`);

  if (!user) return null;
  return (
    <Table
      columns={[
        "Fixture",
        "Date",
        "Points",
        "Winner",
        "Position",
        "Users",
        "FMR",
        "+ Events",
        "+ Points",
        "- Events",
        "- Points",
      ]}
      loading={isLoading}
      title="Game History"
      showSearch={false}
    >
      {gameHistory?.map(
        (
          {
            description,
            timestamp,
            points,
            globalWinner,
            globalPosition,
            globalTotal,
            rating,
            nbPositiveEvents,
            totalPositivePoints,
            nbNegativeEvents,
            totalNegativePoints,
          },
          i
        ) => (
          <Tr key={description + timestamp + i} index={i}>
            <Td>{description}</Td>
            <Td>{moment(timestamp).format("DD/MM/YYYY")}</Td>
            <Td>{points}</Td>
            <Td>{globalWinner}</Td>
            <Td>{globalPosition}</Td>
            <Td>{globalTotal}</Td>
            <Td>{rating}</Td>
            <Td>{nbPositiveEvents}</Td>
            <Td>{totalPositivePoints}</Td>
            <Td>{nbNegativeEvents}</Td>
            <Td>{totalNegativePoints}</Td>
          </Tr>
        )
      )}
    </Table>
  );
};

export default GameHistoryTab;
