import { Fixture, FixtureClassification } from "../../types/fixture";
// @ts-ignore
import exampleFixture from "../../tests/data/fixture.json";
export function classifyFixtures(allActiveFixtures: Fixture[]): FixtureClassification {
  const parsed: any = {
    pre: [],
    prepre: [],
    live: [],
    finished: [],
  };

  const sortedByDate = allActiveFixtures.sort(function(x, y){
    return new Date(x.startTime).getTime() - new Date(y.startTime).getTime();
  })

  sortedByDate.forEach((f) => {

    if (f.status === "live" || f.status === "break") {
      parsed.live.push(f);
    } else if (f.status === "pre") {
      parsed.pre.push(f);
    } else if (f.status === "finished") {
      parsed.finished.push(f);
    } else if (f.status === "prepre") {
      parsed.prepre.push(f);
    }
  });

  return parsed;
}

export function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
}

export function isYes(s: string = "") {
  if (typeof s === "boolean") return s;
  return typeof s === "string" && s?.toLocaleLowerCase()?.trim() === "yes";
}
