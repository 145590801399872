import {
  ChevronDownIcon,
  SearchIcon,
  SortAscendingIcon,
} from "@heroicons/react/solid";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "react-spinners/PulseLoader";
import { PrimaryButton } from "./Main/Button";
import Loading from "./Main/Loading";
import Td from "./Td";
import Tr from "./Tr";

interface TableProps {
  title: string;
  children?: React.ReactNode;
  buttons?: { text: string; onClick: () => void; disabled?: boolean }[];
  columns: string[];
  className?: string;
  showSearch?: boolean;
  onSearch?: (val: string) => void;
  searchValue?: string;
  loading?: boolean;
}

const Table = ({
  columns,
  children,
  title,
  buttons = [],
  showSearch = true,
  className,
  loading = false,
  onSearch,
  searchValue,
}: TableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className={className}>
      <div className="pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {showSearch && (
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <label htmlFor="mobile-search-candidate" className="sr-only">
              Search
            </label>
            <label htmlFor="desktop-search-candidate" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm gap-4">
              <div className="relative focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="mobile-search-candidate"
                  id="mobile-search-candidate"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:hidden border-gray-300"
                  placeholder="Search"
                  value={searchValue ?? searchParams.get("s") ?? ""}
                  onChange={(e) => {
                    if (onSearch) {
                      onSearch(e.target.value);
                    } else {
                      setSearchParams({ s: e.target.value });
                    }
                  }}
                />
                <input
                  type="text"
                  name="desktop-search-candidate"
                  id="desktop-search-candidate"
                  className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Search"
                  onChange={(e) => {
                    if (onSearch) {
                      onSearch(e.target.value);
                    } else {
                      setSearchParams({ s: e.target.value });
                    }
                  }}
                  value={searchValue ?? searchParams.get("s") ?? ""}
                />
              </div>
              <div className="flex">
                {buttons.map((btn, i) => (
                  <PrimaryButton
                    key={btn.text}
                    text={btn.text}
                    onClick={btn.onClick}
                    disabled={btn?.disabled}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="overflow-x-auto"> */}
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((col, i) => (
                  <th
                    key={col}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {!loading ? (
                Array.isArray(children) && children.length > 0 ? (
                  children
                ) : (
                  <Tr>
                    <Td>No results found</Td>
                  </Tr>
                )
              ) : null}
            </tbody>
          </table>
          {loading && (
            <div className="w-full flex justify-center">
              <Loader color="#4f46E5" size={6} />
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Table;
