import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

const SlideOverPanel = ({
  open,
  setOpen,
  content,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  content: React.ReactNode;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Overlay className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="ml-3 h-7">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 absolute right-6"
                          onClick={() => setOpen(false)}
                        >
                          <XIcon className="h-6 w-6" />
                        </button>
                      </div>
                      {content}
                    </div>
                  </div>
                </Dialog.Overlay>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOverPanel;
