import React from "react";
import { isYes } from "../../app/utils/fixtures";
import { Team } from "../../types/team";
import { TeamKitSelector } from "./TeamKitsSelector";

export function TeamDetails({ t }: { t: Team }) {
  if (!t?.id) return <></>;

  return (
    <div className="border-t border-gray-200 mt-4">
      <dl>
        <InfoRow title="id">
          <p>{t.id}</p>
        </InfoRow>
        <InfoRow title="Name" white>
          <p>{t.name}</p>
        </InfoRow>
        <InfoRow title="Enabled">
          <p>{isYes(t.enabled) ? "yes" : "no"}</p>
        </InfoRow>
        <InfoRow title="Code" white>
          <p>{t.code}</p>
        </InfoRow>
        <InfoRow title="Official Name">
          <p>{t.officialName}</p>
        </InfoRow>
        <InfoRow title="Venue" white>
          <p>{t.venueName}</p>
        </InfoRow>
        <InfoRow title="kits (out)">
          <TeamKitSelector team={t} kitType="out" />
        </InfoRow>
        <InfoRow title="kits (GK)">
          <TeamKitSelector team={t} kitType="gk" />
        </InfoRow>
      </dl>
    </div>
  );
}

function InfoRow({
  title,
  children,
  white,
}: {
  title: string;
  children?: any;
  white?: boolean;
}) {
  const bg = white ? "white" : "gray";
  return (
    <div
      className={`bg-${bg}-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
    >
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {children}
      </dd>
    </div>
  );
}
