import { useState } from "react";
import SlideOverPanel from "../../../../components/SlideOverPanel";
import { Fixture } from "../../../../types/fixture";
import { Kit } from "../../../../types/kit";
import KitCard from "./KitCard";
import KitSelector from "./KitSelector";

const KitsTab = ({ fixture }: { fixture?: Fixture }) => {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [selectedKit, setSelectedKit] = useState<{
    team: "home" | "away";
    type: "Out" | "Gk";
  }>();

  const selectKit = (team: "home" | "away", type: "Out" | "Gk") => {
    setSelectedKit({ team, type });
    setSelectorOpen(true);
  };
  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 lg:max-w-4xl mx-auto mt-8">
        <KitCard team="home" type="Out" fixture={fixture} onClick={selectKit} />
        <KitCard team="home" type="Gk" fixture={fixture} onClick={selectKit} />

        <KitCard team="away" type="Out" fixture={fixture} onClick={selectKit} />
        <KitCard team="away" type="Gk" fixture={fixture} onClick={selectKit} />
      </div>
      <SlideOverPanel
        content={
          <KitSelector
            fixture={fixture}
            selectedKit={selectedKit}
            onComplete={() => setSelectorOpen(false)}
          />
        }
        open={selectorOpen}
        setOpen={setSelectorOpen}
      />
    </>
  );
};

export default KitsTab;
