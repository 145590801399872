import { Fixture, FixtureTeam } from "../types/fixture";
import fanslideClient from "./api/FanslideClient";

export type UpdatePrizeDistributionParams = {
  prizeDistributionId: string;
  fixturesIds: string[];
};
export type UpdatePrizeTvChannelParams = {
  tvChannel: string;
  fixturesIds: string[];
};

export const updateFixtureTvChannel = async (
  params: UpdatePrizeTvChannelParams
) => {
  const updatedFixtures = await fanslideClient.updateFixtures({
    updateObject: {
      tvChannel: params.tvChannel,
    },
    fixturesIds: params.fixturesIds,
  });

  return updatedFixtures;
};

export const updateFixturePrizeDistribution = async (
  params: UpdatePrizeDistributionParams
) => {
  const updatedFixtures = await fanslideClient.updateFixtures({
    updateObject: {
      prizeDistributionId: params.prizeDistributionId,
    },
    fixturesIds: params.fixturesIds,
  });

  return updatedFixtures;
};

export const getFixtureAwayAndHomeTeams = (f?: Fixture) => {
  const teams = f?.teams || f?.contestants?.contestant || [{}, {}];
  const homeTeam = teams[0] as FixtureTeam;
  const awayTeam = teams[1] as FixtureTeam;

  return {
    homeTeam,
    awayTeam,
  };
};
