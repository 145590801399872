import { createContext, useState } from "react";
import fanslideClient from "../app/api/FanslideClient";
import { ApiResponse } from "../types/api";

const defaultAuth = {
  authorized: false,
  me: {},
  token: "",
};

type AuthContextType = {
  authorized: boolean;
  me: any;
  token: string;
  loggedOut: boolean;
  login: (email: string, password: string, otp: string) => Promise<ApiResponse>;
  logout: () => Promise<void>;
  autoAuth: () => Promise<void>;
};

export const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({ children }: any) {
  const [authState, setAuthState] = useState(defaultAuth);
  const [loggedOut, setLoggedOut] = useState(false);

  const authorize = (token: string, me: any) => {
    if (!token) return;
    const state = { ...authState };
    window.localStorage.setItem("token", token);
    state.token = token;
    state.authorized = true;
    state.me = me;
    setAuthState(state);
  };

  const deAuthorize = () => {
    window.localStorage.removeItem("token");
    setAuthState(defaultAuth);
  };

  const login = async (
    email: string,
    password: string,
    otp: string
  ): Promise<ApiResponse> => {
    try {
      const fetchOptions = {
        url: "/users/login",
        data: {
          email,
          password,
          otp,
        },
      };

      const response = await fanslideClient.publicPost<{
        tokens: {
          access_token: string;
          refresh_token: string;
        };
        email: string;
        user: any;
      }>(fetchOptions.url, fetchOptions.data, fanslideClient.v1);

      console.log(response);

      const token = response!.tokens.access_token;
      const me = { ...response!.user };

      if (!token || !me?.id) throw new Error("Invalid token or user");

      authorize(token, me);

      return {
        statusCode: 200,
        data: response,
        error: undefined,
      };
    } catch (e) {
      return { statusCode: 400, error: "Your login details are incorrect" };
    }
  };

  const autoAuth = async () => {
    try {
      const token = window.localStorage.getItem("token");
      if (!token) {
        throw new Error();
      }

      const me = await fanslideClient.getMe();

      if (me.error) {
        throw new Error();
      }

      authorize(token, me);
    } catch (err) {
      deAuthorize();
    }
  };

  const logout = async () => {
    deAuthorize();
    setLoggedOut(true);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        autoAuth,
        authorized: authState.authorized,
        me: authState.me,
        token: authState.token,
        loggedOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
