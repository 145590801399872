import { useState } from "react";
import { joinClassNames } from "../../app/helpers";
import { TeamDetails } from "./TeamDetails";
import useGetData from "../../hooks/useGetData";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";

export default function SelectedTeam() {
  const [selectedTab, setSelectedTab] = useState("Info");

  const tabs = [
    { name: "Info", current: selectedTab === "Info" },
    { name: "Players", current: selectedTab === "Players" },
  ];

  const { id } = useParams();
  const query = useGetData(`/admin/teams/${id}`);
  const { data: team, isLoading } = query;

  return (
    <Layout query={query} handleLoading>
      <h1 className="m-6 text-2xl font-bold text-gray-900">{team?.name}</h1>
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-gray-200">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  role="button"
                  onClick={() => setSelectedTab(tab.name)}
                  className={joinClassNames(
                    tab.current
                      ? "border-pink-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {selectedTab === "Info" && <TeamDetails t={team} />}

      {selectedTab === "Players" && (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Team members</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {team?.players?.map((person: any) => (
              <div
                key={person.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <a href="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{`${person?.firstName} ${person?.lastName}`}</p>
                    <p className="text-sm text-gray-500 truncate">{`${person?.position} - ${person?.nationality}`}</p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
}
