import React, { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import EditDataRow from "../../../components/EditDataRow";
import Input from "../../../components/Input";
import { PrimaryButton } from "../../../components/Main/Button";

const DistributionGrid = ({
  initData,
  onChange,
  error,
}: {
  initData?: string;
  onChange: (data?: number[][]) => void;
  error?: any;
}) => {
  const initDataArray = initData
    ? (JSON.parse(JSON.stringify(initData)) as number[][])
    : undefined;
  const [data, setData] = useState(initDataArray);
  useEffect(() => {
    onChange(data);
  }, [data, onChange]);

  return (
    <EditDataRow title="Distribution">
      <div
        className={twMerge(
          "grid grid-cols-7 gap-4",
          error && "border-2 border-red-700"
        )}
      >
        <p className="col-span-2 text-sm font-medium text-gray-500 self-center">
          Position from
        </p>
        <p className="col-span-2 text-sm font-medium text-gray-500 self-center">
          Position to
        </p>
        <p className="col-span-2 text-sm font-medium text-gray-500 self-center">
          % winnings
        </p>
        {data?.map((row, rowIndex) => (
          <React.Fragment key={`row-${rowIndex}`}>
            {row.map((cell, colIndex) => (
              <div className="col-span-2" key={`cell-${rowIndex}-${colIndex}`}>
                <Input
                  value={cell}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[rowIndex][colIndex] = parseFloat(
                      e.target.value || "0"
                    );
                    setData(newData);
                  }}
                />
              </div>
            ))}
            {rowIndex !== 0 && (
              <PrimaryButton
                key={`btn-${rowIndex}`}
                text="-"
                className="justify-center w-2 h-2 p-2 justify-self-center self-center"
                color="gray"
                onClick={(e) => {
                  setData([...data.filter((_, index) => index !== rowIndex)]);
                }}
              />
            )}
          </React.Fragment>
        ))}
        <PrimaryButton
          text="+"
          className="justify-center col-span-2 col-start-3 py-1 w-full"
          color="gray"
          onClick={() => setData([...(data || []), [0, 0, 0]])}
        />
      </div>
    </EditDataRow>
  );
};

export default DistributionGrid;
