import Table from "../../components/Table";
import Td from "../../components/Td";
import moment from "moment";
import Tr from "../../components/Tr";
import { Fixture } from "../../types/fixture";
import useGetData from "../../hooks/useGetData";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

export default function FixturesPage() {
  const navigate = useNavigate();
  const query = useGetData<Fixture[]>("/search/fixtures");
  const { isLoading, data } = query;
  return (
    <Layout query={query}>
      <Table
        title="Fixtures"
        columns={["Fixture", "Kickoff Date", "Status"]}
        loading={isLoading}
        className="mx-8"
      >
        {data?.map((fixture, i) => {
          return (
            <Tr
              key={fixture.id}
              index={i}
              onClick={() => navigate(`/fixtures/${fixture.id}`)}
            >
              <Td>{fixture.description}</Td>
              <Td>{moment(fixture.startTime).format("ddd, MMMM Do, HH:mm")}</Td>
              <Td>{fixture.active === "yes" ? "Active" : "Inactive"}</Td>
            </Tr>
          );
        })}
      </Table>
    </Layout>
  );
}
