import { useAllKitsContext } from "../../contexts/AllKitsContext";
import { Kit } from "../../types/kit";
import ColorBox from "../Main/ColorBox";
import {
  SimpleEmptyTableRow,
  SimpleTableHeaderCell,
  SimpleTableDataCell,
} from "../Main/Table";
import { FixedSizeList as List } from "react-window";
const ITEM_HEIGHT = 60;

export function SelectKitsTable({
  kits,
  assign,
}: {
  kits: Kit[];
  assign: (id: string) => Promise<void>;
}) {
  const allKitsCtx = useAllKitsContext();
  const Row = ({ index, style }: any) => {
    console.log({ style, index });
    const k = kits[index];
    if (!k || !k?.id) return <></>;
    return (
      <KitsTableRow
        style={style}
        kit={k}
        key={k.id}
        white={index % 2 === 0}
        assign={async (k) => {
          await assign(k.id);
        }}
      />
    );
  };
  return (
    <div>
      <KitsTableHeader />
      <List
        height={Math.min(kits?.length * ITEM_HEIGHT, 250)}
        itemCount={kits.length}
        itemSize={ITEM_HEIGHT}
        width={1180}
      >
        {Row}
      </List>
      {kits.length === 0 && <div>No kits yet </div>}
    </div>
  );
}

function KitsTableHeader() {
  return (
    <div className="p-3 border-t border-b border-gray-200 flex flex-row w-full mb-2">
      <div className=" fsw-20 ">Name</div>
      <div className=" fsw-20 ">Colours</div>
      <div className=" fsw-20 ">Image</div>
      <div className=" fsw-20 ">Actions</div>
    </div>
  );
}

function KitsTableRow({
  kit,
  assign,
  style = {},
  white,
}: {
  kit: Kit;
  assign: (k: Kit) => Promise<void>;
  style: any;
  white: boolean;
}) {
  return (
    <div
      key={kit.id}
      className={`flex flex-row items-center   bg-${
        white ? "white" : "gray"
      }-100`}
      style={style}
    >
      <div className=" fsw-20 ">{kit.name}</div>
      <div className="  fsw-20">
        <div className="flex items-center">
          <ColorBox bgColor={kit.primaryColor} textColor={kit.textColor} />
        </div>
      </div>
      <div className=" fsw-20">
        <img src={kit.url} style={{ width: 50, height: 50 }} />
      </div>
      <div className=" fsw-20 items-center">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          onClick={() => assign(kit)}
        >
          Select kit
        </button>
      </div>
    </div>
  );
}
