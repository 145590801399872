import React from "react";
import { text } from "stream/consumers";
import { twMerge } from "tailwind-merge";

function ColorBox({
  bgColor,
  textColor,
}: {
  bgColor: string;
  textColor: string;
}) {
  return (
    <span
      className={twMerge(
        "w-full inline-flex items-center px-2 py-1 rounded-md text-sm font-medium justify-center max-w-[120px]",
        `bg-[${bgColor}] text-[${textColor}]`
      )}
    >
      {textColor}
    </span>
  );
}

export default ColorBox;
