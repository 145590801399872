import React, { useEffect, useState } from "react";
import { ModalCustomStyles } from "../../app/constants";
import {
  AllKitsContextProvider,
  useAllKitsContext,
} from "../../contexts/AllKitsContext";
import { Team } from "../../types/team";
import { PrimaryButton } from "../../components/Main/Button";
import { SelectKitsTable } from "../../components/SingleFixture/SelectKitsTable";
import emptyKit from "../../assets/images/emptyKit.png";
import ReactModal from "react-modal";
import { useTeamsContext } from "../../contexts/TeamsContext";
import { SearchIcon } from "@heroicons/react/solid";

export function TeamKitSelector({
  team,
  kitType,
}: {
  team: Team;
  kitType: "out" | "gk";
}) {
  return (
    <AllKitsContextProvider>
      <div className="flex">
        <KitChooser team={team} kitType={kitType} />
      </div>
    </AllKitsContextProvider>
  );
}

function KitChooser({ team, kitType }: { team: Team; kitType: "out" | "gk" }) {
  const [kitsIds, setKitsIds] = useState<string[]>([]);
  const kitsCtx = useAllKitsContext();

  useEffect(() => {
    if (kitType === "out") {
      setKitsIds(team.outKitIds || []);
    } else if (kitType === "gk") {
      setKitsIds(team.gkKitIds || []);
    }
  }, [kitType, team]);

  if (kitsCtx.loading) return <></>;

  console.log({
    kitsIds,
  });

  return (
    <div className="flex flex-row">
      {kitsIds?.map((kitId) => {
        const k = kitsCtx.findKit(kitId);
        if (!k.url)
          return <React.Fragment key={Math.random()}></React.Fragment>;
        return (
          <img
            src={k.url}
            className="w-24 mb-2"
            key={`${kitType}-img-${k.id}-url-${k.url}`}
            alt={`${kitType}-img-${k.id}-url-${k.url}`}
            role="button"
            onClick={async (e) => {
              // remove kit
              const accept = await window.confirm(
                `are you sure you want to remove kit ${k.name} from ${team?.name} ?? `
              );
              if (accept) {
                const affectedTeams = await kitsCtx.deleteKitFromTeam({
                  fromIds: [team.id],
                  kitId: k.id,
                  kitType: kitType === "gk" ? "gkKitId" : "outKitId",
                });

                // remove kit icon
                const all = [...kitsIds];
                const ind = all.indexOf(kitId);
                ind > -1 && all.splice(ind, 1);
                setKitsIds(all);
              }
            }}
          />
        );
      })}
      <KitChooserModal kitType={kitType} team={team} />
    </div>
  );
}

function KitChooserModal({
  team,
  kitType,
}: {
  team: Team;
  kitType: "out" | "gk";
}) {
  const kitsCtx = useAllKitsContext();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [view, setView] = React.useState<"this" | "all">("this");
  const teamsCtx = useTeamsContext();
  const [searchKitsNeedle, setSearchKitsNeedle] = useState("");

  const assign = async (kitId: string) => {
    const r = await teamsCtx.assignKitToTeamsMutation.mutateAsync({
      teamId: team.id,
      kitId: kitId,
      kitType: kitType,
    });

    console.log({
      r,
    });
    closeModal();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  console.log({
    kitsCtx,
    // teamQuery,
  });

  const thisTeamKitsSet = new Set([
    ...(team.outKitIds || []),
    ...(team.gkKitIds || []),
  ]);

  const TabButton = ({ text, whichToSet }: any) => {
    return (
      <button
        type="button"
        className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-${
          view === whichToSet ? "blue" : "white"
        }-500 text-sm font-medium text-${
          view === whichToSet ? "white" : "gray"
        }-700 hover:bg-${
          view === whichToSet ? "blue" : "white"
        }-500 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 w-half`}
        onClick={() => setView(whichToSet)}
      >
        <span className="w-full">{text}</span>
      </button>
    );
  };

  return (
    <div className="p-0 m-0">
      <img
        src={emptyKit}
        className="w-24 mb-2"
        onClick={() => openModal()}
        role="button"
      />
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalCustomStyles({})}
        contentLabel="Example Modal"
      >
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Select New Kit
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                click on the button next to each item to select it.
              </p>
            </div>

            <div>
              <div className="relative text-gray-400 focus-within:text-gray-600 fsw-70">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <SearchIcon
                    className="flex-shrink-0 h-5 w-100"
                    aria-hidden="true"
                  />
                </div>
                <input
                  name="desktop-search-field"
                  id="desktop-search-field"
                  className="hidden h-full w-full border-gray-200 py-2 pl-8 pr-3 text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-gray-200 focus:placeholder-gray-400 sm:block"
                  placeholder={"search kits"}
                  value={searchKitsNeedle}
                  onChange={(e) =>
                    setSearchKitsNeedle(
                      (e.target.value || "")?.toLocaleLowerCase()
                    )
                  }
                  type="search"
                />
              </div>
            </div>

            <span className="relative z-0 inline-flex shadow-sm rounded-md w-full">
              <TabButton text={`${team.shortName}'s kits`} whichToSet="this" />
              <TabButton text="All kits" whichToSet="all" />
            </span>

            <SelectKitsTable
              assign={assign}
              kits={
                view === "all"
                  ? kitsCtx.kits.filter((k) =>
                      k.name?.toLocaleLowerCase().includes(searchKitsNeedle)
                    )
                  : Array.from(thisTeamKitsSet)
                      .map((kId) => kitsCtx.findKit(kId))
                      .filter((k) =>
                        k.name?.toLocaleLowerCase().includes(searchKitsNeedle)
                      )
              }
            />
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <PrimaryButton
              text="cancel"
              onClick={(e) => {
                closeModal();
                console.log({ e });
              }}
              color="gray"
              className="mr-2"
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
