import moment from "moment";
import React, { useEffect } from "react";
import EditDataRow from "../../../components/EditDataRow";
import JSONDisplay from "../../../components/JSONDisplay";
import ColorBox from "../../../components/Main/ColorBox";
import Table from "../../../components/Table";
import Td from "../../../components/Td";
import Tr from "../../../components/Tr";
import useGetData from "../../../hooks/useGetData";
import { User } from "../../../types/user";
import Tick from "../../../components/Tick";
import Cross from "../../../components/Cross";
import SlideOverPanel from "../../../components/SlideOverPanel";
import { PrimaryButton } from "../../../components/Main/Button";

type Props = {
  user?: User;
};

type ResponsibleGamblingHistoryItem = {
  id: string;
  createdAt: string;
  entity: string;
  request: any;
};

const ResponsibleGamblingTab = ({ user }: Props) => {
  const {
    data: history,
    isLoading,
    error,
  } = useGetData<ResponsibleGamblingHistoryItem[]>(
    `/admin/users/${user?.id}/responsible-gambling-history`
  );

  const [selectedJSON, setSelectedJSON] = React.useState<string>();

  if (!user) return null;
  return (
    <>
      <div className="divide-y mb-8">
        <EditDataRow
          title="Deposit Limit"
          value={user.depositLimit ? <Tick /> : <Cross />}
        />
        <EditDataRow
          title="Self Exclusion"
          value={user.selfExclusion ? <Tick /> : <Cross />}
        />
        <EditDataRow title="Time Out" value={user.timeOut ? "ON" : <Cross />} />
      </div>
      <Table
        columns={["ID", "Date", "Entity", "Request"]}
        loading={isLoading}
        title="History"
        showSearch={false}
      >
        {history?.map(({ id, createdAt, entity, request }, i) => (
          <Tr key={i} index={i}>
            <Td>{id}</Td>
            <Td>{moment(createdAt).format("DD/MM/YYYY")}</Td>
            <Td>{entity}</Td>
            <Td>
              <PrimaryButton
                onClick={() => setSelectedJSON(request)}
                text="View"
              />
            </Td>
          </Tr>
        ))}
      </Table>
      <SlideOverPanel
        open={!!selectedJSON}
        setOpen={(value) => setSelectedJSON(undefined)}
        content={<JSONDisplay data={selectedJSON} />}
      />
    </>
  );
};

export default ResponsibleGamblingTab;
