import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Team } from "../types/team";
import fanslideClient from "../app/api/FanslideClient";
import { useSearchParams } from "react-router-dom";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { fsQueryClient } from "../Router";

type TeamsContextType = {
  listenToTeamSelect: (e: React.ChangeEvent<HTMLInputElement>, f: Team) => void;
  selectedTeams: Team[];
  clearTeamSelection: () => void;
  isTeamSelected: (f: Team) => boolean;
  teams: Team[];
  searchNeedle: string;
  setSearchNeedle: (s: string) => void;
  loading: boolean;
  view: string;
  cancelForm: () => void;
  showSingleSelectedTeam: (id: string) => Promise<void>;
  selectedSingleTeam: Team;
  assignKitToTeamsMutation: UseMutationResult<
    Team,
    unknown,
    {
      teamId: string;
      kitType: "out" | "gk";
      kitId: string;
    },
    unknown
  >;
};

export const TeamsContext = createContext({} as TeamsContextType);
export function TeamsContextProvider({ children }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchNeedle, setSearchNeedle] = useState("");
  const teamsQuery = useQuery<Team[], Error>(
    ["teams", searchParams.get("s")],
    () => fanslideClient.getTeams(searchParams.get("s") || "")
  );
  const singleTeamQuery = useQuery<Team, Error>(
    ["teams", searchParams.get("id")],
    async () => {
      const id = searchParams.get("id");
      if (!id) return {} as Team;
      const t = await fanslideClient.getSingleTeam(id);
      return t;
    }
  );

  const cancelForm = () => {
    const obj: any = {
      view: "all",
    };

    if (searchParams.get("s")) {
      obj["s"] = searchParams.get("s");
    }

    setSearchParams(obj);
  };

  const showSingleSelectedTeam = async (id: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      view: "single",
      id,
    });
  };

  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  const addTeamToSelectedTeams = (team: Team) => {
    const existed = selectedTeams.find((s) => s.id === team.id);
    if (!existed) {
      setSelectedTeams((selected) => [...selected, team]);
    }
  };

  const removeTeamFromSelectedTeams = (team: Team) => {
    const existed = selectedTeams.find((s) => s.id === team.id);
    if (existed) {
      setSelectedTeams((selected) => selected.filter((s) => s.id !== team.id));
    }
  };

  const listenToTeamSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    f: Team
  ): void => {
    if (e.target.checked) {
      addTeamToSelectedTeams(f);
    } else {
      removeTeamFromSelectedTeams(f);
    }
  };

  const clearTeamSelection = () => setSelectedTeams([]);
  const isTeamSelected = (team: Team) =>
    selectedTeams.filter((f) => f.id === team.id)?.length > 0;

  const updateAfterSave = (key: string, newValue: any) => {
    // update teams query
    // update selected team query
    fsQueryClient.setQueryData<Team>(
      ["teams", singleTeamQuery.data?.id],
      (oldTeam) => {
        if (!oldTeam) return {} as Team;
        return {
          ...oldTeam,
          [key]: newValue,
        };
      }
    );
  };

  const assignKitToTeamsMutation = useMutation(
    async ({
      teamId,
      kitType,
      kitId,
    }: {
      teamId: string;
      kitType: "out" | "gk";
      kitId: string;
    }) => {
      const r = await fanslideClient.assignKitToTeams({
        toIds: [teamId],
        kitId,
        kitType: kitType === "out" ? "outKitId" : "gkKitId",
      });
      return r as Team;
    },
    {
      onSuccess: (data, variables, context) => {
        const key = variables.kitType === "out" ? "outKitIds" : "gkKitIds";
        let value: string[] = [];
        if (singleTeamQuery.data && singleTeamQuery.data[key]) {
          value = singleTeamQuery.data[key] || [];
        }
        value.push(variables.kitId);
        updateAfterSave(key, value);
      },
    }
  );

  return (
    <TeamsContext.Provider
      value={{
        listenToTeamSelect,
        selectedTeams,
        clearTeamSelection,
        isTeamSelected,

        teams:
          teamsQuery.data?.filter((t) =>
            t.name.toLowerCase().includes(searchNeedle.toLowerCase())
          ) || [],
        loading: teamsQuery.isLoading,
        searchNeedle,
        setSearchNeedle,
        view: searchParams.get("view") || "all",
        cancelForm,
        showSingleSelectedTeam,
        selectedSingleTeam: singleTeamQuery.data || ({} as Team),
        assignKitToTeamsMutation,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
}

export const useTeamsContext = () => useContext(TeamsContext);
