import React from "react";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => (
    <input ref={ref} className="bg-gray-100 h-full w-full p-2" {...props} />
  )
);

export default Input;
