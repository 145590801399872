import React, { useContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import SignIn from "./components/Main/Login";
import Router from "./Router";
import { AuthContext } from "./contexts/AuthContext";
import Loading from "./components/Main/Loading";

function App() {
  const [loading, setLoading] = useState(true);

  const authorizer = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      await authorizer.autoAuth();
      setLoading(false);
    })();
  }, []);

  if (!authorizer.authorized && !loading) {
    return <SignIn />;
  }

  if (loading) {
    return <Loading />;
  }

  return <Router />;
}

export default App;
