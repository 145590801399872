import React from "react";
import JSONPretty from "react-json-pretty";

const JSONPrettyTheme = require("react-json-pretty/dist/JSONPretty");

const JSONDisplay = ({ data }: { data: any }) => (
  <div className="max-w-sm">
    <JSONPretty
      data={data}
      theme={JSONPrettyTheme}
      style={{
        fontWeight: "normal",
        overflow: "scroll",
      }}
    />
  </div>
);

export default JSONDisplay;
