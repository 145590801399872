import { BanIcon, XIcon, CheckIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { twMerge } from "tailwind-merge";
import fanslideClient from "../../../app/api/FanslideClient";
import { Fixture } from "../../../types/fixture";

const ActivateButton = ({ fixture }: { fixture?: Fixture }) => {
  const active = fixture?.active === "yes";
  const [isWaiting, setIsWaiting] = useState(false);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    //TODO: use v2 api here
    () => {
      if (active) {
        return fanslideClient.deactivateFixture(fixture?.id || "");
      }
      return fanslideClient.activateFixture(fixture?.id || "");
    },
    {
      onSuccess: () => {
        setIsWaiting(true);
        setTimeout(() => {
          queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
          setIsWaiting(false);
        }, 30000);
      },
    }
  );
  if (!fixture) return null;
  return (
    <button
      type="button"
      className={twMerge(
        "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white",
        active && "bg-red-600 hover:bg-red-700",
        !active && "bg-green-600 hover:bg-green-700",
        isWaiting && "bg-gray-600 hover:bg-gray-700"
      )}
      onClick={() => mutation.mutate()}
      disabled={isWaiting}
    >
      {isWaiting ? (
        <BanIcon className="-ml-1 mr-3 h-5 w-5" />
      ) : active ? (
        <XIcon className="-ml-1 mr-3 h-5 w-5" />
      ) : (
        <CheckIcon className="-ml-1 mr-3 h-5 w-5" />
      )}

      {isWaiting
        ? active
          ? "Deactivating..."
          : "Activating..."
        : active
        ? "Deactivate"
        : "Activate"}
    </button>
  );
};

export default ActivateButton;
