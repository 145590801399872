import React from "react";
import useGetData from "../hooks/useGetData";
import DisplayServerFeedback from "./Main/DisplayServerFeedback";
import Loading from "./Main/Loading";
import Sidebar from "./Main/SideBar";

type Props = {
  query?: ReturnType<typeof useGetData>;
  children: React.ReactNode;
  handleLoading?: boolean;
};

const Main = ({ children }: { children: React.ReactNode }) => (
  <div className="h-full grid grid-cols-[0.1fr,1fr] md:grid-cols-[0.2fr,1fr]">
    <Sidebar />
    <div className="flex flex-col h-full bg-white pt-8">{children}</div>
  </div>
);

const Layout = ({ children, query, handleLoading }: Props) => {
  if (!query) return <Main>{children}</Main>;

  const { isLoading, error, data } = query;

  if (handleLoading && isLoading)
    return (
      <Main>
        <Loading />
      </Main>
    );

  if (error) {
    return (
      <Main>
        <div className="px-6">
          <DisplayServerFeedback
            feedback={{ type: "error", message: error.message }}
          />
        </div>
      </Main>
    );
  }

  if (!isLoading && !data) {
    return (
      <Main>
        <div className="px-6">
          <DisplayServerFeedback
            feedback={{ type: "error", message: "Didn't get any data" }}
          />
        </div>
      </Main>
    );
  }

  return <Main>{children}</Main>;
};

export default Layout;
