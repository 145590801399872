import { useTeamsContext } from "../../contexts/TeamsContext";
import { twMerge } from "tailwind-merge";
import useGetData from "../../hooks/useGetData";
import { Team } from "../../types/team";
import Table from "../../components/Table";
import Td from "../../components/Td";
import Tr from "../../components/Tr";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

export default function TeamsPage() {
  const ctx = useTeamsContext();

  const query = useGetData<Team[]>("/search/teams");
  const { isLoading, data } = query;
  const navigate = useNavigate();

  return (
    <Layout query={query}>
      <div
        className={twMerge(
          "grid",
          !!ctx.selectedSingleTeam?.id ? "grid-cols-[0.5fr,1fr]" : "grid-cols-1"
        )}
      >
        <Table
          title="Teams"
          columns={["Name", "Short Name", "Venue"]}
          loading={isLoading}
          className="mx-8"
        >
          {data?.map((team, i) => (
            <Tr
              key={team.id}
              onClick={() => {
                navigate(`/teams/${team.id}`);
              }}
            >
              <Td>{team.name}</Td>
              <Td>{team.shortName}</Td>
              <Td>{team.venueName}</Td>
            </Tr>
          ))}
        </Table>
      </div>
    </Layout>
  );
}
