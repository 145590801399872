import React from "react";
import { isIsoDate } from "../app/utils/fixtures";

export type SearchT = {
  key: string;
  term: string;
  secondKey?: string;
};

export type AscDesc = "asc" | "desc";

export type SortT = {
  key: string;
  type: AscDesc;
};

type UseMapInterFace<T> = [
  allArray: T[],
  setAll: (array: T[], key?: string) => void,
  getOne: (k: string) => T | undefined,
  setSome: (array: T[], key?: string) => void,
  search: (search: SearchT) => void,
  sort: (sort: SortT) => void,
  currentSearch: SearchT,
  currentSort: SortT
];

const checkTwoStringInclude = (str: string, needle: string) => {
  return (str || "")
    .trim()
    .toLocaleLowerCase()
    .includes((needle || "").trim().toLocaleLowerCase());
};

type UseMapParams = {
  defaultSortKey?: string;
  defaultSearchKey?: string;
};

const sort2Dates = (dateStr1: string, dateStr2: string, type: AscDesc) => {
  const d1 = new Date(dateStr1);
  const d2 = new Date(dateStr2);
  if ((d1 as any) === "Invalid Date" || (d2 as any) === "Invalid Date") {
    return 0;
  } else if (type === "asc") {
    return d2.getTime() - d1.getTime();
  } else if (type === "desc") {
    return d1.getTime() - d2.getTime();
  }
  return 0;
};

/**
 * for safety, we meed to choose the right type to sort on.
 * so if unexpected type (eg. null, undefined) this function should not break its caller
 */
const chooseRightType = (p1: any, p2: any) => {
  if (p1 === undefined || p2 === undefined || p1 === null || p2 === null) {
    return undefined;
  }

  if (typeof p1 !== typeof p2) {
    if (typeof p1 === "undefined") return typeof p2;
  }

  return typeof p1;
};

function useMap<T>(params: UseMapParams = {}): UseMapInterFace<T> {
  const [data, setData] = React.useState<Map<string, T>>(new Map());
  const [search, setSearch] = React.useState<SearchT>({
    term: "",
    key: params.defaultSearchKey || "",
  });
  const [sortT, setSortT] = React.useState<SortT>({
    type: "asc",
    key: params.defaultSortKey || "",
  });

  const getArray = () => {
    const arr: T[] = [];
    data.forEach((val, k) => {
      // console.log(k, val);
      arr.push(val);
    });
    return arr;
  };

  const setAll = (arr: T[], key: string = "id") => {
    const map = new Map<string, T>();
    for (const val of arr) {
      const stringK = (val as any)[key];
      map.set(stringK, val);
    }
    setData(map);
  };

  const getOne = (key: string) => {
    return data.get(key);
  };

  const setSome = (arr: T[], key = "id") => {
    const updatedMap = new Map(data);
    arr.forEach((obj) => {
      const stringK = (obj as any)[key];
      updatedMap.set(stringK, obj);
    });
    setData(updatedMap);
  };

  const filterComplexObject = (obj: T, key: string, needle: string, secondKey: string): boolean => {
    const property = (obj as any)[key];
    // console.log({ property });
    if (typeof property === "string") return checkTwoStringInclude(property, needle);
    else if (typeof property === "number") return property === Number(needle);
    else if (typeof property === "object") {
      if (Array.isArray(property)) {
        // case of array.
      } else {
        // case of object
        if (secondKey) return filterComplexObject(property as T, secondKey, needle, "");
      }
      return false;
    } else {
      return false;
    }
  };

  const filterComplexArrayOfObjects = React.useCallback((arr: T[], key: string, needle: string, secondKey) => {
    return arr.filter((obj) => {
      return filterComplexObject(obj, key, needle, secondKey);
    });
  }, []);

  const sortComplexArrayOfObjects = React.useCallback((arr: T[], key: string, type: AscDesc) => {
    return arr.sort((a, b) => {
      const p1 = (a as any)[key];
      const p2 = (b as any)[key];

      const commonType = chooseRightType(p1, p2);

      if (typeof commonType === "number") {
        if (type === "asc") return Number(p1) - Number(p2);
        else if (type === "desc") return Number(p2) - Number(p1);
      } else if (typeof commonType === "string") {
        // case of string as date
        if (isIsoDate(p1) || isIsoDate(p2)) {
          return sort2Dates(p1, p2, type);
        } else {
          // case of normal string
          if (type === "asc") {
            if (typeof p1?.localeCompare === "function") return ((p1 || "") as string).localeCompare(p2);
            else return p1 > p2 ? 1 : -1;
          } else if (type === "desc") {
            if (typeof p2?.localeCompare === "function") return ((p2 || "") as string).localeCompare(p1);
            else return p2 > p1 ? -1 : 1;
          }
        }
      } else if (typeof commonType === "object") {
        if (Array.isArray(commonType)) {
          // case of array.
        } else {
          // case of object
        }
        return 0;
      } else {
        return 0;
      }

      return 0;
    });
  }, []);

  const searchData = (all: T[]) => {
    const { key, term, secondKey } = search;

    // console.log({ all, key, term });

    if (!key || !term) return all;
    const filtered = filterComplexArrayOfObjects(all, key, term, secondKey);
    return filtered;
  };

  const sortData = (arr: T[]) => {
    const { key, type } = sortT;
    // console.log({ arr, key, type });
    if (!key || !type) return arr;
    const sorted = sortComplexArrayOfObjects(arr, key, type);
    return sorted;
  };

  const exposeFinalArray = () => {
    const all = getArray();
    // console.log({ allBefore: all });
    const searched = searchData(all);
    const sorted = sortData(searched);
    return sorted;
  };

  return [exposeFinalArray(), setAll, getOne, setSome, setSearch, setSortT, search, sortT];
}

export default useMap;
