import ClockLoader from "react-spinners/ClockLoader";

export default function Loading() {
  return (
    <div className="App">
      <div className="App-header" style={{ background: "white" }}>
        <ClockLoader />
      </div>
    </div>
  );
}
