import { createContext, useContext, useEffect, useState } from "react";
import fanslideClient from "../app/api/FanslideClient";
import { Kit } from "../types/kit";
import { Team } from "../types/team";

type KitsShowing = "table" | "form";
type DeleteKitFromTeamParams = {
  fromIds: string[];
  kitId: string;
  kitType: "gkKitId" | "outKitId";
};

type AllKitsContextType = {
  kits: Kit[];
  getAllKits: () => Promise<void>;
  loading: boolean;
  showing: KitsShowing;
  changeShowing: (newView: KitsShowing) => void;
  kitToEdit: Kit | undefined;
  setKitToEdit: (kit: Kit | undefined) => void;
  createKit: (kit: any) => Promise<void>;
  updateKit: (kit: any) => Promise<void>;
  findKit: (kitId: string) => Kit;
  searchNeedle: string;
  setSearchNeedle: (s: string) => void;
  deleteKitFromTeam: (params: DeleteKitFromTeamParams) => Promise<Team[]>;
};
export const AllKitsContext = createContext({} as AllKitsContextType);

export function AllKitsContextProvider({ children }: any) {
  const [kits, setKits] = useState<Kit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showing, setShowing] = useState<KitsShowing>("table");
  const [kitToEdit, setKitToEdit] = useState<Kit>();
  const [searchNeedle, setSearchNeedle] = useState<string>("");

  const setSortedKits = (kits: Kit[]) => {
    const sorted = (kits || []).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setKits(sorted);
  };

  useEffect(() => {
    (async () => {
      await getAllKits();
      setLoading(false);
    })();
  }, []);

  const getAllKits = async () => {
    const kits = await fanslideClient.getAllKits();
    setSortedKits(kits);
  };

  const createKit = async (kit: any) => {
    setLoading(true);
    const r = await fanslideClient.createKit(kit);
    setSortedKits([...kits, r.data]);
    setLoading(false);
  };

  const updateKit = async (kit: any) => {
    setLoading(true);
    const r = await fanslideClient.updateKit(kit);
    const newKits = kits.map((k) => {
      if (k?.id === r.data?.id) {
        return r.data;
      }
      return k;
    });
    setSortedKits(newKits);
    setLoading(false);
  };

  const findKit = (kidId: string) => {
    return (kits || []).find((k) => k.id === kidId) || ({} as Kit);
  };

  const changeShowing = (newView: KitsShowing) => setShowing(newView);

  const deleteKitFromTeam = async (params: DeleteKitFromTeamParams) => {
    return fanslideClient.deleteKitFromTeam(params);
  };

  return (
    <AllKitsContext.Provider
      value={{
        kits: kits?.filter((k) =>
          k.name.toLowerCase().includes(searchNeedle.toLowerCase())
        ),
        getAllKits,
        loading,
        showing,
        changeShowing,
        kitToEdit,
        setKitToEdit,
        createKit,
        updateKit,
        findKit,
        searchNeedle,
        setSearchNeedle,
        deleteKitFromTeam,
      }}
    >
      {children}
    </AllKitsContext.Provider>
  );
}

export const useAllKitsContext = () => useContext(AllKitsContext);
