import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import Tabs from "../../../components/Tabs";
import useGetData from "../../../hooks/useGetData";
import { Tab } from "../../../types";
import { User } from "../../../types/user";
import Header from "../../Fixtures/SelectedFixture/Header";
import GameHistoryTab from "./GameHistoryTab";
import InfoTab from "./InfoTab";
import TransactionHistoryTab from "./TransactionHistoryTab";
import IdentityVerificationTab from "./IdentityVerificationTab";
import ResponsibleGamblingTab from "./ResponsibleGamblingTab";

const SelectedUser = () => {
  const { id } = useParams();
  const query = useGetData<User>(`/admin/users/${id}`);
  const { data: user } = query;
  const { hash } = useLocation();
  const navigate = useNavigate();

  if (!hash) navigate("#info");

  const tabs: Tab[] = [
    {
      name: "Info",
      href: "#info",
      component: <InfoTab user={user} />,
    },
    {
      name: "Responsible Gambling",
      href: "#responsible-gambling",
      component: <ResponsibleGamblingTab user={user} />,
    },
    {
      name: "Identity Verification",
      href: "#identity",
      component: <IdentityVerificationTab user={user} />,
    },
    {
      name: "Game History",
      href: "#game-history",
      component: <GameHistoryTab user={user} />,
    },
    {
      name: "Transaction History",
      href: "#transaction-history",
      component: <TransactionHistoryTab user={user} />,
    },
  ];
  const currentTab = tabs.find((tab) => tab.href === hash);

  return (
    <Layout query={query} handleLoading>
      <main className="w-5/6 mx-auto">
        <Header title={user?.fanslideUsername} buttons={[]} />
        <Tabs tabs={tabs} currentTab={currentTab} />
        {currentTab?.component}
      </main>
    </Layout>
  );
};

export default SelectedUser;
