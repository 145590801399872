import { usePlayersContext } from "../../contexts/PlayersContext";
import Debug from "../../components/Main/Debug";
import Table from "../../components/Table";
import Tr from "../../components/Tr";
import Td from "../../components/Td";
import moment from "moment";
import { Player } from "../../types/player";
import useGetData from "../../hooks/useGetData";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

export default function PlayersPage() {
  const navigate = useNavigate();
  const playersCtx = usePlayersContext();
  const query = useGetData<Player[]>("/search/players");
  const { isLoading, data } = query;
  return (
    <Layout query={query}>
      {playersCtx.view === "all" && (
        <Table
          title="Players"
          columns={["Name", "Date of Birth"]}
          loading={isLoading}
          className="mx-8"
        >
          {data?.map((player, i) => (
            <Tr
              key={player.id}
              index={i}
              onClick={() => navigate(`/players/${player.id}`)}
            >
              <Td>{player.firstName + " " + player.lastName}</Td>
              <Td>{moment(player.dateOfBirth).format("YYYY-MM-DD")}</Td>
            </Tr>
          ))}
        </Table>
      )}
      {playersCtx.view === "single" && (
        <>
          <Debug
            obj={{
              player: playersCtx.selectedSinglePlayer,
            }}
          />
        </>
      )}
    </Layout>
  );
}
