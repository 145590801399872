import { createContext, useContext, useEffect, useState } from "react";
import fanslideClient from "../app/api/FanslideClient";
import { Fixture } from "../types/fixture";

type AllFixturesContextType = {
  getAllFixtures: () => Promise<any[]>;
  listenToFixtureSelect: (
    e: React.ChangeEvent<HTMLInputElement>,
    f: Fixture
  ) => void;
  selectedFixtures: Fixture[];
  clearFixtureSelection: () => void;
  isFixtureSelected: (f: Fixture) => boolean;
  loading: boolean;
  fixtures: Fixture[];
  setSearchNeedle: (s: string) => void;
  searchNeedle: string;
};

export const AllFixturesContext = createContext({} as AllFixturesContextType);
export function AllFixturesContextProvider({ children }: any) {
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [selectedFixtures, setSelectedFixtures] = useState<Fixture[]>([]);
  const [searchNeedle, setSearchNeedle] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const getAllFixtures = async (): Promise<any[]> => {
    setLoading(true);
    const r = await fanslideClient.getAllFixtures(searchNeedle);
    setFixtures(r || []);
    setLoading(false);
    return r;
  };

  useEffect(() => {
    getAllFixtures();
  }, []);

  const addFixtureToSelectedFixtures = (fixture: Fixture) => {
    const existed = selectedFixtures.find((s) => s.id === fixture.id);
    if (!existed) {
      setSelectedFixtures((selected) => [...selected, fixture]);
    }
  };

  const removeFixtureFromSelectedFixtures = (fixture: Fixture) => {
    const existed = selectedFixtures.find((s) => s.id === fixture.id);
    if (existed) {
      setSelectedFixtures((selected) =>
        selected.filter((s) => s.id !== fixture.id)
      );
    }
  };

  const listenToFixtureSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    f: Fixture
  ): void => {
    if (e.target.checked) {
      addFixtureToSelectedFixtures(f);
    } else {
      removeFixtureFromSelectedFixtures(f);
    }
  };

  const clearFixtureSelection = () => setSelectedFixtures([]);
  const isFixtureSelected = (fixture: Fixture) =>
    selectedFixtures.filter((f) => f.id === fixture.id)?.length > 0;

  return (
    <AllFixturesContext.Provider
      value={{
        fixtures: fixtures?.filter((f) =>
          f.description?.toLowerCase().includes(searchNeedle?.toLowerCase())
        ),
        listenToFixtureSelect,
        selectedFixtures,
        clearFixtureSelection,
        isFixtureSelected,
        loading,
        setSearchNeedle,
        getAllFixtures,
        searchNeedle,
      }}
    >
      {children}
    </AllFixturesContext.Provider>
  );
}

export const useAllFixturesContext = () => useContext(AllFixturesContext);
