import moment from "moment";
import React, { useEffect } from "react";
import currencyFormat from "../../../app/utils/currencyFormat";
import ColorBox from "../../../components/Main/ColorBox";
import Table from "../../../components/Table";
import Td from "../../../components/Td";
import Tr from "../../../components/Tr";
import useGetData from "../../../hooks/useGetData";
import { User } from "../../../types/user";

type Props = {
  user?: User;
};

type Transaction = {
  adjustment: number;
  balance: number;
  created: string;
  createdAt: string;
  entity: string;
  lastDigits: string;
  modified: string;
  orderCode: number;
  transactionType: string;
  txRef: string;
  updatedAt: string;
  userId: string;
};

const TransactionHistoryTab = ({ user }: Props) => {
  const {
    data: transactions,
    isLoading,
    error,
  } = useGetData<Transaction[]>(`/admin/users/${user?.id}/transaction-history`);

  useEffect(() => {
    console.log(transactions);
  }, [transactions]);

  if (!user) return null;
  return (
    <Table
      columns={["Reference", "Date", "Type", "Amount", "Balance"]}
      loading={isLoading}
      title="Transaction History"
      showSearch={false}
    >
      {transactions?.map(
        ({ txRef, createdAt, transactionType, adjustment, balance }, i) => (
          <Tr key={txRef} index={i}>
            <Td>{txRef}</Td>
            <Td>{moment(createdAt).format("YYYY-MM-DD HH:mm")}</Td>
            <Td>{transactionType}</Td>
            <Td className={adjustment < 0 ? "text-red-600" : "text-green-600"}>
              {currencyFormat(Math.abs(adjustment))}
            </Td>
            <Td>{currencyFormat(balance)}</Td>
          </Tr>
        )
      )}
    </Table>
  );
};

export default TransactionHistoryTab;
