import React from "react";
import FixturesBulkActionSheet from "../../components/Home/FixturesBulkActionSheet";
import { useActiveFixturesContext } from "../../contexts/ActiveFixturesContext";
import Table from "../../components/Table";
import Tr from "../../components/Tr";
import Td from "../../components/Td";

import { Fixture } from "../../types/fixture";
import moment from "moment";
import Layout from "../../components/Layout";

const renderTable = (title: string, data: Fixture[]) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Table
      title={title}
      columns={["Fixture", "TV Channel", "Kickoff Date"]}
      className="mb-8 mx-8"
      showSearch={false}
    >
      {data.map((fixture, i) => (
        <Tr
          key={fixture.id}
          index={i}
          onClick={() => (window.location.href = `/fixtures/${fixture.id}`)}
        >
          <Td className="w-1/2">{fixture.description}</Td>
          <Td className="w-1/4">{fixture.tvChannel}</Td>
          <Td className="w-1/4">
            {moment(fixture.startTime).format("ddd, MMMM Do, HH:mm")}
          </Td>
        </Tr>
      ))}
    </Table>
  );
};

export default function Home() {
  const ctx = useActiveFixturesContext();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const promises = [
        ctx.getActiveFixtures(),
        ctx.getAllPrizeDistributions(),
        ctx.getAllTvChannels(),
      ];
      await Promise.all(promises);
      setLoading(false);
    })();
  }, []);

  const live = ctx.classifiedFixtures.live;
  const startingShortly = ctx.classifiedFixtures.pre;
  const upcoming = ctx.classifiedFixtures.prepre;
  const finished = ctx.classifiedFixtures.finished;

  console.log(ctx.classifiedFixtures);

  return (
    <Layout>
      <>
        {ctx.selectedFixtures?.length > 0 && <FixturesBulkActionSheet />}
        {renderTable(`Live (${live?.length})`, live)}
        {renderTable(
          `Starting shortly (${startingShortly?.length})`,
          startingShortly
        )}
        {renderTable(`Upcoming (${upcoming?.length})`, upcoming)}
        {renderTable(`Finished (${finished?.length})`, finished)}
      </>
    </Layout>
  );
}
