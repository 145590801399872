import moment from "moment";
import { useEffect } from "react";
import Table from "../../../components/Table";
import Td from "../../../components/Td";
import Tr from "../../../components/Tr";
import useGetData from "../../../hooks/useGetData";
import { User } from "../../../types/user";
import JSONDisplay from "../../../components/JSONDisplay";
import React from "react";
import { PrimaryButton } from "../../../components/Main/Button";
import SlideOverPanel from "../../../components/SlideOverPanel";

type Props = {
  user?: User;
};

type IdentityVerification = {
  entity: string;
  createdAt: string;
  request: string;
  response: string;
  status: string;
};

const IdentityVerificationTab = ({ user }: Props) => {
  const {
    data: history,
    isLoading,
    error,
  } = useGetData<IdentityVerification[]>(
    `/admin/users/${user?.email}/identity-verification-history`
  );

  const [selectedJSON, setSelectedJSON] = React.useState<string>();

  if (!user) return null;
  return (
    <>
      <Table
        columns={["Table", "Created At", "Status", "Request", "Response"]}
        loading={isLoading}
        title="Transaction History"
        showSearch={false}
      >
        {history?.map(({ entity, createdAt, request, response, status }, i) => (
          <Tr key={entity + createdAt + request} index={i}>
            <Td>{entity}</Td>
            <Td>{moment(createdAt).format("DD/MM/YYYY")}</Td>
            <Td>{status}</Td>
            <Td>
              <PrimaryButton
                onClick={() => setSelectedJSON(request)}
                text="View"
              />
            </Td>
            <Td>
              <PrimaryButton
                onClick={() => setSelectedJSON(response)}
                text="View"
              />
            </Td>
          </Tr>
        ))}
      </Table>
      <SlideOverPanel
        open={!!selectedJSON}
        setOpen={(value) => setSelectedJSON(undefined)}
        content={<JSONDisplay data={selectedJSON} />}
      />
    </>
  );
};

export default IdentityVerificationTab;
