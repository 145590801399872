import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import Debug from "../../components/Main/Debug";
import useGetData from "../../hooks/useGetData";
import { Player } from "../../types/player";

type Props = {};

const SelectedPlayer = (props: Props) => {
  const { id } = useParams();
  const query = useGetData<Player[]>(`/admin/players/${id}`);
  const { data } = query;

  return (
    <Layout query={query}>
      <Debug
        obj={{
          player: data,
        }}
      />
    </Layout>
  );
};

export default SelectedPlayer;
