import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import fanslideClient from "../../../../app/api/FanslideClient";
import { PrimaryButton } from "../../../../components/Main/Button";
import Loading from "../../../../components/Main/Loading";
import Table from "../../../../components/Table";
import Td from "../../../../components/Td";
import Tr from "../../../../components/Tr";
import useGetData from "../../../../hooks/useGetData";
import { Fixture, FixtureTeam } from "../../../../types/fixture";
import { FixturePlayer } from "../../../../types/player";
import PlayerKitImage from "./PlayerKitImage";

const TeamTab = ({
  fixture,
  team,
  teamType,
}: {
  fixture?: Fixture;
  team: FixtureTeam;
  teamType: "home" | "away";
}) => {
  const {
    isLoading,
    error,
    data: allPlayers,
  } = useGetData<FixturePlayer[]>(
    `/admin/fixtures/${fixture?.id}/players`,
    undefined,
    { enabled: !!fixture?.id }
  );

  const [search, setSearch] = useState("");

  console.log(allPlayers);

  const players = allPlayers
    ?.filter((p) => p.teamId === team.id)
    .filter(
      (p) =>
        p.firstName.toLowerCase().includes(search.toLowerCase()) ||
        p.lastName.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => (a.shirtNumber > b.shirtNumber ? 1 : -1));

  const [editedPlayers, setEditedPlayers] = useState<FixturePlayer[]>([]);

  const editPlayer = (
    player: FixturePlayer,
    fieldName: keyof FixturePlayer,
    value: string
  ) => {
    const existingList = [...editedPlayers];
    let existingIndex = existingList.findIndex((p) => p.id === player.id);
    if (existingIndex > -1) {
      //@ts-ignore
      existingList[existingIndex][fieldName] = value;
      setEditedPlayers([...existingList]);
    } else {
      setEditedPlayers([...editedPlayers, { ...player, [fieldName]: value }]);
    }
  };

  const queryClient = useQueryClient();
  const { mutate, isLoading: isMutationLoading } = useMutation(
    () => {
      const promises = editedPlayers.map((player) =>
        fanslideClient.updatePlayerNamesFixture({
          playerId: player.id,
          fixtureId: fixture?.id,
          data: player,
        })
      );
      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}/players`);
        setEditedPlayers([]);
      },
      onError: () => {
        console.log("errored");
      },
    }
  );

  console.log(players);

  return (
    <>
      <Table
        columns={[
          "Shirt Number",
          "Full Name",
          "Shirt Name",
          "Display Name",
          "Kit",
        ]}
        title="Players"
        onSearch={(search) => setSearch(search)}
        searchValue={search}
        loading={isLoading || isMutationLoading}
      >
        {players?.map((player, i) => (
          <Tr key={player.id} index={i}>
            <Td>{player.shirtNumber}</Td>
            <Td>{player.firstName + " " + player.lastName}</Td>
            <Td>
              <input
                type="text"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder={player.displayName}
                onChange={(e) =>
                  editPlayer(player, "displayName", e.target.value)
                }
              />
            </Td>
            <Td>
              <input
                type="text"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder={player.fullDisplayName}
                onChange={(e) =>
                  editPlayer(player, "fullDisplayName", e.target.value)
                }
              />
            </Td>
            <Td>
              <div className="h-20 w-20">
                <PlayerKitImage
                  player={player}
                  fixture={fixture}
                  teamType={teamType}
                />
              </div>
            </Td>
          </Tr>
        ))}
      </Table>
      <div className="w-full flex justify-center mt-8 mb-16">
        <PrimaryButton text="Submit Changes" onClick={() => mutate()} />
      </div>
    </>
  );
};

export default TeamTab;
