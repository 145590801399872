import { twMerge } from "tailwind-merge";

const EditDataRow = ({
  title,
  value,
  onClick,
  onClickText,
  gray,
  children,
}: {
  title: string | React.ReactNode;
  value?: any;
  onClick?: () => void;
  onClickText?: string;
  gray?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        "grid grid-cols-[0.5fr,1fr,0.25fr] py-4",
        gray && "bg-gray-50"
      )}
    >
      <dt className="text-sm font-medium text-gray-500 self-center">{title}</dt>
      {typeof value === "string" || typeof value === "number" ? (
        <dd className="flex text-sm text-gray-900 ">{value}</dd>
      ) : (
        value ?? <div className="flex ">{children}</div>
      )}
      {onClick && (
        <span className="flex justify-end">
          <button
            type="button"
            className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500"
            onClick={onClick}
          >
            {onClickText || "Update"}
          </button>
        </span>
      )}
    </div>
  );
};

export default EditDataRow;
