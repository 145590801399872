import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import fanslideClient from "../../../../app/api/FanslideClient";
import useGetData from "../../../../hooks/useGetData";
import { Fixture } from "../../../../types/fixture";
import KitsTable from "../../../Kits/KitsTable";

type Props = {
  fixture?: Fixture;
  selectedKit?: { team: "home" | "away"; type: "Out" | "Gk" };
  onComplete: () => void;
};

const KitSelector = ({ fixture, selectedKit, onComplete }: Props) => {
  const [search, setSearch] = useState("");
  const { isLoading, data, error } = useGetData(`/admin/kits?s=${search}`);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (kitId: string) =>
      fanslideClient.assignKitToFixture({
        fixtureId: fixture!.id,
        kitId: kitId,
        teamType: selectedKit!.team,
        kitType: selectedKit!.type === "Gk" ? "goalKeeper" : "players",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
        onComplete();
      },
    }
  );

  if (!selectedKit) onComplete();

  return (
    <KitsTable
      data={data}
      isLoading={isLoading}
      onSearch={(val: string) => setSearch(val)}
      searchValue={search}
      onClickRow={(kit) => {
        mutation.mutate(kit.id);
      }}
    />
  );
};

export default KitSelector;
