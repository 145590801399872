import { createContext, useContext, useEffect, useState } from "react";
import { Player } from "../types/player";
import fanslideClient from "../app/api/FanslideClient";
import { useSearchParams } from "react-router-dom";

type PlayersContextType = {
  players: Player[];
  showSingleSelectedPlayer: (id: string) => Promise<void>;
  selectedSinglePlayer: any;
  loading: boolean;
  searchNeedle: string;
  setSearchNeedle: (s: string) => void;
  view: "all" | string;
  getPlayers: (s: string) => Promise<void>;
  cancelForm: () => void;
};

export const PlayersContext = createContext({} as PlayersContextType);
export function PlayersContextProvider({ children }: any) {
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedSinglePlayer, setSelectedSinglePlayer] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      await getPlayers();
      if (searchParams.has("id")) {
        showSingleSelectedPlayer(searchParams.get("id") || "");
      }
    })();
  }, []);

  const getPlayers = async (s: string = "") => {
    setLoading(true);
    const allPlayers: Player[] = await fanslideClient.getPlayers(
      s || searchParams.get("s") || ""
    );
    setPlayers(allPlayers || []);
    setLoading(false);
  };

  const showSingleSelectedPlayer = async (playerId: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      view: "single",
      id: playerId,
    });
    // const player = await fanslideClient.getSinglePlayer(playerId);
    const player = players.find((p) => p.id === playerId) || {};
    setSelectedSinglePlayer(player);
  };

  const cancelForm = () => {
    const obj: any = {
      view: "all",
    };

    if (searchParams.get("s")) {
      obj["s"] = searchParams.get("s");
    }

    setSearchParams(obj);
  };
  return (
    <PlayersContext.Provider
      value={{
        players:
          players.filter(
            (p) =>
              p.firstName
                .toLowerCase()
                .includes(searchParams.get("s")?.toLowerCase() || "") ||
              p.lastName
                .toLowerCase()
                .includes(searchParams.get("s")?.toLowerCase() || "")
          ) || ([] as Player[]),
        showSingleSelectedPlayer,
        selectedSinglePlayer,
        loading,
        searchNeedle: searchParams.get("s") || "",
        setSearchNeedle: (s: string) => {
          const obj: any = { s };
          if (!obj.s) delete obj.s;
          setSearchParams(obj);
        },
        view: searchParams.get("view") || "all",
        getPlayers,
        cancelForm,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
}

export const usePlayersContext = () => useContext(PlayersContext);
