import {
  ApiResponse,
  ApiParams,
  ProtectedApiParams,
  ProtectedApiParamsAndMethod,
  ApiParamsAndMethod,
} from "../../types/api";

export const getFanslideBaseUrl = (): string => {
  const env = process.env.REACT_APP_ENV;

  switch (env) {
    case "local":
      return "localhost:5454";
    case "development":
    case "dev":
      return "dev.fanslide.com";
    case "production":
    case "prod":
      return "fanslide.com";
  }
  return "localhost:5454";
};

export function getUA() {
  return `FANSLIDE|v|-|dev|-}`;
}

export async function postWithFiles(
  params: ProtectedApiParams & { fileList: FileList }
): Promise<ApiResponse> {
  const result: ApiResponse = {} as ApiResponse;
  let response;
  try {
    const dataToSend = new FormData();
    const fileObj = params?.fileList?.item(0)?.slice();

    if (!fileObj) throw new Error("File must be set");

    dataToSend.append("file", fileObj);

    Object.keys(params.data).forEach((key) => {
      dataToSend.append(key, params.data[key]);
    });

    console.log({
      dataToSend,
    });

    response = await fetch(params.url, {
      method: "POST",
      headers: {
        token: params.token,
      },
      body: dataToSend,
    });

    // if (!response.ok) {
    //   throw new Error("failed");
    // }

    result.statusCode = response.status;

    const _res = await response.json(); // parses JSON response into native JavaScript objects

    if (_res?.error) {
      result.error = _res.error;
      return result;
    }

    result.data = _res.data;

    return result;
  } catch (error) {
    console.error(error);
    //@ts-ignore
    if (typeof error === "object") result.error = error?.message;
    return result;
  }
}
