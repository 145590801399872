import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { twMerge } from "tailwind-merge";
import fanslideClient from "../../../../app/api/FanslideClient";
import { PrimaryButton } from "../../../../components/Main/Button";
import Loading from "../../../../components/Main/Loading";
import useGetData from "../../../../hooks/useGetData";
import { Fixture, PrizeDistribution } from "../../../../types/fixture";

const PrizeDistributionSelector = ({
  fixture,
  onComplete,
}: {
  fixture?: Fixture;
  onComplete: () => void;
}) => {
  const { isLoading, data, error } = useGetData<PrizeDistribution[]>(
    "/admin/prize-distributions"
  );

  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<PrizeDistribution | undefined>(
    data?.find((pd) => pd.id === fixture?.prizeDistributionId)
  );

  const mutation = useMutation(
    //TODO: use v2 api here
    () =>
      fanslideClient.updateFixtures({
        updateObject: {
          prizeDistributionId: selected?.id,
        },
        fixturesIds: [fixture?.id ?? ""],
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
        onComplete();
      },
    }
  );

  if (!fixture) return null;
  if (isLoading) return <Loading />;

  return (
    <div className="w-full">
      <p className="text-lg font-bold mb-8">Select Prize Distribution:</p>
      <div className="flex flex-col gap-y-2 items-start pl-4">
        {data?.map((prizeDist) => (
          <button
            key={prizeDist.id}
            className={twMerge(
              "block",
              selected?.id === prizeDist.id && "font-bold"
            )}
            onClick={() => setSelected(prizeDist)}
          >
            {prizeDist.name}
          </button>
        ))}
      </div>
      <div className="mt-8 mx-auto w-fit">
        <PrimaryButton
          text="Submit"
          onClick={() => mutation.mutate()}
          disabled={!selected}
        />
      </div>
    </div>
  );
};

export default PrizeDistributionSelector;
