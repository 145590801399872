import { twMerge } from "tailwind-merge";

const Section = ({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={twMerge("divide-y mx-6", className)}>
    <p className="text-lg font-medium text-gray-500 mb-4">{title}</p>
    {children}
  </div>
);

export default Section;
