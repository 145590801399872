import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { twMerge } from "tailwind-merge";
import fanslideClient from "../../../../app/api/FanslideClient";
import { PrimaryButton } from "../../../../components/Main/Button";
import Loading from "../../../../components/Main/Loading";
import useGetData from "../../../../hooks/useGetData";
import { Fixture } from "../../../../types/fixture";

type Props = {
  fixture: Fixture;
  onComplete: () => void;
};

const ContestSelector = ({ fixture, onComplete }: Props) => {
  const queryClient = useQueryClient();
  const { isLoading, data, error } = useGetData<{ contestTypeId: string }[]>(
    "/admin/contest-types"
  );
  const [selected, setSelected] = useState<{ contestTypeId: string }>();

  const { mutate, isLoading: isPosting } = useMutation(
    async (contestTypeId: string) => {
      const result = await fanslideClient.addRemoveFixtureContestId(
        fixture?.id || "",
        contestTypeId
      );
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/admin/fixtures/${fixture?.id}`);
      },
    }
  );

  const availableContestTypes = data?.filter(
    (item) => !fixture.fixtureContestTypes?.includes(item.contestTypeId)
  );

  if (!fixture) return null;
  if (isLoading) return <Loading />;

  return (
    <div className="w-full">
      <p className="text-lg font-bold mb-8">Select Contest:</p>
      <div className="flex flex-col gap-y-2 items-start">
        {availableContestTypes && availableContestTypes.length > 0 ? (
          availableContestTypes?.map((contestType) => (
            <button
              key={contestType.contestTypeId}
              className={twMerge(
                "block",
                selected?.contestTypeId === contestType.contestTypeId &&
                  "font-bold"
              )}
              onClick={() => setSelected(contestType)}
            >
              {contestType.contestTypeId}
            </button>
          ))
        ) : (
          <p>No available contest types</p>
        )}
      </div>
      <div className="mt-8 items-start">
        <PrimaryButton
          text="Add Contest"
          onClick={() => mutate(selected?.contestTypeId || "")}
          disabled={!selected || isPosting}
        />
      </div>
    </div>
  );
};

export default ContestSelector;
