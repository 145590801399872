import React from "react";
import { Fixture } from "../../../../types/fixture";
import { FixturePlayer } from "../../../../types/player";
import shadow2xKit from "../../../../assets/images/kit_shadow_transparent_2x.png";

type Props = {
  player?: FixturePlayer;
  fixture?: Fixture;
  teamType: "home" | "away";
};

const PlayerKitImage = ({ fixture, player, teamType }: Props) => {
  let bgImage = shadow2xKit;
  if (player?.position === "Goalkeeper") {
    if (teamType === "home") {
      bgImage = fixture?.kits?.homeKitGK ?? "";
    } else {
      bgImage = fixture?.kits?.awayKitGK ?? "";
    }
  } else {
    if (teamType === "home") {
      bgImage = fixture?.kits?.homeKit ?? "";
    } else {
      bgImage = fixture?.kits?.awayKit ?? "";
    }
  }

  return (
    <a target="_blank" href={player?.kitUrl} rel="noreferrer">
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <img
          src={`${player?.kitUrl}?v=${Math.random()}`}
          alt={`kit for ${bgImage}`}
        />
      </div>
    </a>
  );
};

export default PlayerKitImage;
