import React, { useContext } from "react";
import {
  FlagIcon,
  ClockIcon,
  IdentificationIcon,
  UserGroupIcon,
  UsersIcon,
  PencilAltIcon,
  HomeIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { joinClassNames } from "../../app/helpers";
import fanslideLogo from "../../assets/images/fanslide_black.png";
import AvatarImage from "../../assets/images/avatar_empty.png";
import { FANSLIDE_LOGO_URL } from "../../app/constants";
import { AuthContext } from "../../contexts/AuthContext";
import { LogoutIcon } from "@heroicons/react/outline";

type NavigationItem = {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
  circleColor?: string;
};

const navigation: NavigationItem[] = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Fixtures", href: "/fixtures", icon: FlagIcon, current: false },
  { name: "Teams", href: "/teams", icon: UserGroupIcon, current: false },
  {
    name: "Players",
    href: "/players",
    icon: IdentificationIcon,
    current: false,
  },
  { name: "Kits", href: "/kits", icon: PencilAltIcon, current: false },
  { name: "Users", href: "/users", icon: UsersIcon, current: false },
  {
    name: "Contests",
    href: "/contest-types",
    icon: PencilIcon,
    current: false,
  },
];

const secondaryNavigation: NavigationItem[] = [
  {
    name: "Coming soon 1",
    href: "#",
    icon: ClockIcon,
    current: false,
    circleColor: "indigo",
  },
  {
    name: "Coming soon 2",
    href: "#",
    icon: ClockIcon,
    current: false,
    circleColor: "green",
  },
  {
    name: "Coming soon 3",
    href: "#",
    icon: ClockIcon,
    current: false,
    circleColor: "yellow",
  },
];

export default function Sidebar() {
  const auth = useContext(AuthContext);
  return (
    <nav className="flex flex-col border-r border-gray-200 pt-5  bg-gray-100">
      <NaveSideBarLogo />

      <UserNameInfo />
      <div className="px-3">
        <div className="space-y-1">
          {navigation.map((item) => {
            return <NavSideBarItem item={item} key={item.name} />;
          })}
        </div>

        {/* <div className="mt-8">
          <NaveSideBarSectionTitle item={{ name: "For Future Use" }} />
          <div
            className="mt-1 space-y-1"
            role="group"
            aria-labelledby="desktop-teams-headline"
          >
            {secondaryNavigation.map((item) => {
              return <SecondaryNavSideBarItem item={item} key={item.name} />;
            })}
          </div>
        </div> */}
      </div>
    </nav>
  );
}

function SecondaryNavSideBarItem({ item }: { item: NavigationItem }) {
  const location = useLocation();
  const [activeTab] = useState(location.pathname);
  item.href === activeTab ? (item.current = true) : (item.current = false);

  return (
    <Link
      to={item.href}
      className={joinClassNames(
        item.current
          ? "bg-gray-300 "
          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center px-3 py-2 text-sm leading-6 font-medium rounded-md text-gray-600"
      )}
      aria-current={item.current ? "page" : undefined}
    >
      <span
        className={`w-2.5 h-2.5 mr-4 bg-${
          item.circleColor || "yellow"
        }-500 rounded-full`}
        aria-hidden="true"
      ></span>
      <span className="truncate">{item.name}</span>
    </Link>
  );
}

function NavSideBarItem({ item }: { item: NavigationItem }) {
  const location = useLocation();
  const [activeTab] = useState(location.pathname);
  item.href === activeTab ? (item.current = true) : (item.current = false);

  return (
    <Link
      to={item.href}
      className={joinClassNames(
        item.current
          ? "bg-gray-200 text-gray-900 "
          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      )}
      aria-current={item.current ? "page" : undefined}
    >
      <item.icon
        className={`text-gray-${
          item.current ? "500" : "400"
        } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
        aria-hidden="true"
      />
      {item.name}
    </Link>
  );
}

function NaveSideBarSectionTitle({ item }: { item: { name: string } }) {
  return (
    <h3
      className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
      id="desktop-teams-headline"
    >
      {item.name}
    </h3>
  );
}

function NaveSideBarLogo() {
  return (
    <div className="flex items-center flex-shrink-0 px-6">
      <img className="h-8 w-auto" src={fanslideLogo} alt="Fanslide CMS" />
      {/* <img className="h-8 w-auto" src={FANSLIDE_LOGO_URL} alt="Fanslide CMS" /> */}
    </div>
  );
}

function UserNameInfo() {
  const auth = useContext(AuthContext);
  return (
    <div className="relative my-4 group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500 flex flex-col md:flex-row items-center">
      <span className="flex w-full justify-between items-center">
        <span className="flex min-w-0 items-center justify-between space-x-3">
          <img
            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            src={auth.me?.picture || AvatarImage}
            alt=""
          />
          <span className="flex-1 flex flex-col">
            <span className="text-gray-900 text-sm font-medium truncate">
              {auth.me?.firstName + " " + auth.me?.lastName}
            </span>
            <span className="text-gray-500 text-xs truncate">
              @{auth.me?.fanslideUsername + ""}
            </span>
          </span>
        </span>
      </span>
      <div className="w-full flex justify-end">
        <button
          type="button"
          title="Logout"
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => auth.logout()}
        >
          <LogoutIcon
            className="h-4 w-4"
            aria-hidden="true"
            aria-label="logout"
          />
          {/* Log out */}
        </button>
      </div>
    </div>
  );
}
