import React from "react";
import { Kit } from "../../types/kit";
import { useForm, FieldError } from "react-hook-form";
import { useQueryClient } from "react-query";

const emptyKit: Kit = {
  id: "",
  name: "",
  primaryColor: "#000000",
  nameColor: "#eeeeee",
  textColor: "#ffffff",
  url: "",
  imageFile: undefined,
};
export function NewKitForm({
  kitToEdit,
  cancelForm,
  createKit,
  updateKit,
}: {
  kitToEdit?: Kit;
  cancelForm: () => void;
  createKit: (kit: any) => Promise<void>;
  updateKit: (kit: any) => Promise<void>;
}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Kit>({
    defaultValues: kitToEdit || emptyKit,
  });

  const queryClient = useQueryClient();
  return (
    <div className="w-100 px-10 pb-5">
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(async (data) => {
          console.log({
            data,
          });
          if (kitToEdit?.id) {
            await updateKit(data);
          } else {
            await createKit(data);
          }
          queryClient.invalidateQueries("/admin/kits");
          cancelForm();
        })}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {kitToEdit?.id ? "Updating kit .." : "Create New Kit"}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {kitToEdit?.id ? `kit id: ${kitToEdit?.id}` : ""}
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Image
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    <span className="h-100 w-20 rounded-full overflow-hidden bg-gray-100">
                      {watch("url") ? (
                        <img src={watch("url")} />
                      ) : (
                        <ImagePlaceholder imageList={watch("imageFile")} />
                      )}
                    </span>
                    {!watch("url") && (
                      <input
                        type="file"
                        {...register("imageFile", {
                          required: watch("id") ? false : true,
                          onChange: () => {
                            setValue("url", "");
                          },
                        })}
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("name", { required: true })}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <FormError error={errors.name} />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Primary Color
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="color"
                    {...register("primaryColor", { required: true })}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  <FormError error={errors.primaryColor} />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Text Color
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="color"
                    {...register("textColor", { required: true })}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  <FormError error={errors.textColor} />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name Color
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="color"
                    {...register("nameColor", { required: true })}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  <FormError error={errors.nameColor} />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Type
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("type")}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value=""></option>
                    <option value="home">Home</option>
                    <option value="away">Away</option>
                    <option value="alternate">Alternate</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={cancelForm}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function ImagePlaceholder({ imageList }: { imageList: FileList | undefined }) {
  if (imageList?.item(0)?.name) {
    const imageFile = imageList.item(0);
    if (imageFile) {
      const url: any = URL.createObjectURL(imageFile);
      return <img src={url} width="100%" />;
    }
  }
  return (
    <svg
      className="h-full w-full text-gray-300"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );
}

function FormError({ error }: { error: FieldError | undefined }) {
  if (!error) return <></>;
  return (
    <>
      <label className="block text-sm font-medium text-gray-700"></label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <p className="text-sm text-red-500">
          {error.message || "field is required"}
        </p>
      </div>
    </>
  );
}
