import moment from "moment";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../../../components/Layout";
import Table from "../../../../../components/Table";
import Td from "../../../../../components/Td";
import Tr from "../../../../../components/Tr";
import useGetData from "../../../../../hooks/useGetData";
import fanslideClient from "../../../../../app/api/FanslideClient";

type Props = {};

type ContestUser = {
  contestTypeId: string;
  modified: string;
  userId: string;
  fanslideUsername?: string;
  entity: string;
  transactionId: string;
  allocatedContestInstance: string;
  fixtureId: string;
  created: string;
  joined: string;
  voided: boolean;
};

const SelectedContest = (props: Props) => {
  const { id, contestTypeId } = useParams();
  const [selectedUsers, setSelectedUsers] = React.useState<ContestUser[]>([]);

  const query = useGetData<ContestUser[]>(`/admin/fixtures/${id}/contests/${contestTypeId}`);
  const { isLoading, data } = query;

  const sortedByJoined = data?.sort(function (x, y) {
    return new Date(x.joined).getTime() - new Date(y.joined).getTime();
  });

  const checkIfUserIsSelected = useCallback(
    (userId: string) => {
      return selectedUsers.some((user) => user.userId === userId);
    },
    [selectedUsers]
  );

  const handleSelectUser = useCallback(
    (user: ContestUser) => {
      const userId = user.userId;
      if (checkIfUserIsSelected(userId)) {
        setSelectedUsers(selectedUsers.filter((user) => user.userId !== userId));
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    },
    [selectedUsers, checkIfUserIsSelected]
  );

  const handleSelectAll = useCallback(() => {
    if (selectedUsers.length === sortedByJoined?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(sortedByJoined ?? []);
    }
  }, [selectedUsers, sortedByJoined]);

  const voidBetsForSelectedUsers = useCallback(async () => {
    const confirmed = window.confirm(`Are you sure you want to void bets for ${selectedUsers?.length}  users?`);
    if (confirmed) {
      try {
        console.log(`Voiding bets for ${selectedUsers.length} users`, { selectedUsers });
        const params = selectedUsers.map((user) => ({
          userId: user.userId,
          transactionId: user.transactionId,
        }));
        await fanslideClient.post(`/admin/${id}/void-bets-by-users`, params);
        setSelectedUsers([]);
        alert("Bets voided successfully");
        window.location.reload();
      } catch (e) {
        console.error(e);
        alert(`Error voiding bets for ${selectedUsers.length} users\n${e}`);
      }
    }
  }, [selectedUsers, id]);

  const voidBetsForSelectedUsersButton = {
    text: "Void Bets for Selected Users",
    onClick: voidBetsForSelectedUsers,
    disabled: selectedUsers.length === 0,
  };

  const selectAllButton = {
    text: selectedUsers.length === sortedByJoined?.length ? "Deselect All" : "Select All",
    onClick: handleSelectAll,
  };

  return (
    <Layout query={query}>
      <Table
        title={`${contestTypeId} - ${data?.length ?? "0"} users`}
        columns={["", "User", "Contest", "Date", "Allocated?", "Voided"]}
        className="mx-8"
        loading={isLoading}
        buttons={[selectAllButton, voidBetsForSelectedUsersButton]}
      >
        {sortedByJoined?.map((user, i) => (
          <Tr key={user.userId} index={i}>
            <Td className="w-1">
              <input
                type="checkbox"
                onChange={() => {
                  handleSelectUser(user);
                }}
                checked={checkIfUserIsSelected(user.userId)}
              />
            </Td>
            <Td>{user.fanslideUsername ? user.fanslideUsername : user.userId}</Td>
            <Td>{user.contestTypeId}</Td>
            <Td>{moment(user.joined).format("YYYY-MM-DD HH:mm")}</Td>
            <Td>{user.allocatedContestInstance || ""}</Td>
            <Td>{user.voided ? <span className="text-red-500">Yes</span> : "No"}</Td>
          </Tr>
        ))}
      </Table>
    </Layout>
  );
};

export default SelectedContest;
