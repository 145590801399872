import React from "react";
import ColorBox from "../../components/Main/ColorBox";
import Table from "../../components/Table";
import Td from "../../components/Td";
import Tr from "../../components/Tr";
import { Kit } from "../../types/kit";

type Props = {
  data?: Kit[];
  isLoading?: boolean;
  onClickRow?: (kit: Kit) => void;
  buttons?: { text: string; onClick: () => void }[];
  onSearch?: (search: string) => void;
  searchValue?: string;
};

const KitsTable = ({
  data,
  isLoading,
  onClickRow,
  buttons,
  onSearch,
  searchValue,
}: Props) => {
  return (
    <Table
      columns={["Name", "Colours", "Image"]}
      title="Kits"
      loading={isLoading}
      buttons={buttons}
      onSearch={onSearch}
      searchValue={searchValue}
      className="mx-8"
    >
      {data?.map((kit, i) => (
        <Tr
          key={kit.id}
          index={i}
          onClick={() => {
            onClickRow?.(kit);
          }}
        >
          <Td>{kit.name}</Td>
          <Td>
            <ColorBox bgColor={kit.primaryColor} textColor={kit.textColor} />
          </Td>
          <Td>
            <img src={kit.url} width="50px" alt={kit.name} />
          </Td>
        </Tr>
      ))}
    </Table>
  );
};

export default KitsTable;
