import { createContext, useContext, useEffect, useMemo, useState } from "react";
import fanslideClient from "../app/api/FanslideClient";
import { classifyFixtures } from "../app/utils/fixtures";
import useMap, { SearchT, SortT } from "../hooks/useMap";
import { Fixture, FixtureClassification, PrizeDistribution, TvChannel } from "../types/fixture";

type UpdatePrizeDistributionParams = { prizeDistributionId: string; fixturesIds: string[] };
type UpdatePrizeTvChannelParams = { tvChannel: string; fixturesIds: string[] };

type FixtureContextType = {
  activeFixtures: Fixture[];
  getActiveFixtures: () => Promise<void>;
  classifiedFixtures: FixtureClassification;
  listenToFixtureSelect: (e: React.ChangeEvent<HTMLInputElement>, f: Fixture) => void;
  selectedFixtures: Fixture[];
  prizeDistributions: PrizeDistribution[];
  getAllPrizeDistributions: () => Promise<void>;
  tvChannels: TvChannel[];
  getAllTvChannels: () => Promise<void>;
  clearFixtureSelection: () => void;
  isFixtureSelected: (f: Fixture) => boolean;
  updateTvChannel: (params: UpdatePrizeTvChannelParams) => Promise<Fixture[]>;
  updatePrizeDistribution: (params: UpdatePrizeDistributionParams) => Promise<Fixture[]>;
  searchActiveFixtures: (param: SearchT) => void;
  sortActiveFixtures: (param: SortT) => void;
  currentSearch: SearchT;
  currentSort: SortT;
};

export const ActiveFixturesContext = createContext({} as FixtureContextType);
export function ActiveFixturesContextProvider({ children }: any) {
  const [
    activeFixtures,
    setActiveFixtures,
    getOnceActiveFixture,
    setSomeActiveFixture,
    searchActiveFixtures,
    sortActiveFixtures,
    currentSearch,
    currentSort,
  ] = useMap<Fixture>({
    defaultSortKey: "startTime",
  });
  const classifiedFixtures = useMemo(() => classifyFixtures(activeFixtures), [activeFixtures, currentSort]);
  const [selectedFixtures, setSelectedFixtures] = useState<Fixture[]>([]);
  const [prizeDistributions, setPrizeDistributions] = useState<PrizeDistribution[]>([]);
  const [tvChannels, setTvChannels] = useState<TvChannel[]>([]);

  const getActiveFixtures = async () => {
    try {
      const r = await fanslideClient.getActiveFixtures();
      setActiveFixtures(r);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPrizeDistributions = async () => {
    const r = await fanslideClient.getAllPrizeDistributions();
    setPrizeDistributions(r);
  };

  const getAllTvChannels = async () => {
    const r = await fanslideClient.getAllTvChannels();
    setTvChannels(r);
  };

  const addFixtureToSelectedFixtures = (fixture: Fixture) => {
    const existed = selectedFixtures.find((s) => s.id === fixture.id);
    if (!existed) {
      setSelectedFixtures((selected) => [...selected, fixture]);
    }
  };

  const removeFixtureFromSelectedFixtures = (fixture: Fixture) => {
    const existed = selectedFixtures.find((s) => s.id === fixture.id);
    if (existed) {
      setSelectedFixtures((selected) => selected.filter((s) => s.id !== fixture.id));
    }
  };

  const listenToFixtureSelect = (e: React.ChangeEvent<HTMLInputElement>, f: Fixture): void => {
    if (e.target.checked) {
      addFixtureToSelectedFixtures(f);
    } else {
      removeFixtureFromSelectedFixtures(f);
    }
  };

  const clearFixtureSelection = () => setSelectedFixtures([]);
  const isFixtureSelected = (fixture: Fixture) =>
    selectedFixtures.filter((f) => f.id === fixture.id)?.length > 0;


  const updateTvChannel = async (params: UpdatePrizeTvChannelParams) => {
    const updateTvChannelResponse = await fanslideClient.updateFixtures({
      updateObject: {
        tvChannel: params.tvChannel,
      },
      fixturesIds: params.fixturesIds,
    });

    setSomeActiveFixture(updateTvChannelResponse);

    return updateTvChannelResponse;
  };

  const updatePrizeDistribution = async (params: UpdatePrizeDistributionParams) => {
    const updatePrizeDistributionResponse = await fanslideClient.updateFixtures({
      updateObject: {
        prizeDistributionId: params.prizeDistributionId,
      },
      fixturesIds: params.fixturesIds,
    });

    console.log({
      updatePrizeDistributionResponse,
    });

    setSomeActiveFixture(updatePrizeDistributionResponse);

    return updatePrizeDistributionResponse;
  };

  return (
    <ActiveFixturesContext.Provider
      value={{
        activeFixtures,
        getActiveFixtures,
        classifiedFixtures,
        listenToFixtureSelect,
        selectedFixtures,
        prizeDistributions,
        getAllPrizeDistributions,
        tvChannels,
        getAllTvChannels,
        clearFixtureSelection,
        isFixtureSelected,
        updateTvChannel,
        updatePrizeDistribution,
        searchActiveFixtures,
        sortActiveFixtures,
        currentSearch,
        currentSort,
      }}
    >
      {children}
    </ActiveFixturesContext.Provider>
  );
}

export const useActiveFixturesContext = () => useContext(ActiveFixturesContext);
