import allPlayersJson from "./mocks/allPlayers.json";
import { ApiResponse, UpdateFixturesRequest } from "../../types/api";
import { FixturePlayer, PartialPlayer, Player } from "../../types/player";
import { Kit } from "../../types/kit";
import { User } from "../../types/user";
import { getFanslideBaseUrl, postWithFiles } from "./helpers";
import { Fixture } from "../../types/fixture";
import axios from "axios";
import { Team } from "../../types/team";
import { ContestTypeT } from "../../types/contests";

const baseUrl = getFanslideBaseUrl();

class FanslideClient {
  internal = `https://internalapi.${baseUrl}/api`;
  v2 = `https://api.${baseUrl}/v2`;
  v1 = `https://api.${baseUrl}/api`;

  async post<T = any>(path: string, data: any, base = ""): Promise<T> {
    const url = `${base ? base : this.v2}${path}`;
    try {
      const r = await axios.post<ApiResponse>(url, data, {
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token") || "",
        },
      });
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log(`error in POST request`, { [url]: error });
      console.error(error);
      if (error.response) {
        throw new Error(
          error.response.status + ": " + error.response.data.error
        );
      } else {
        throw new Error(error.message || "unknown server error");
      }
    }
  }

  async get<T = any>(path: string, base = ""): Promise<T> {
    const url = `${base ? base : this.v2}${path}`;
    try {
      const r = await axios.get<ApiResponse>(url, {
        method: "GET",
        headers: {
          token: window.localStorage.getItem("token") || "",
        },
      });
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log({ [url]: error });
      if (error?.response?.status === 403) {
        window.localStorage.removeItem("token");
        window.location.reload();
      }
      throw new Error(
        error.response?.message || error.message || "unknown server error"
      );
    }
  }

  async publicPost<T = any>(path: string, data: any, base = "") {
    const url = `${base ? base : this.v2}${path}`;
    try {
      console.log("posting data:", data);
      const r = await axios.post<ApiResponse<T>>(url, data);
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log({ [url]: error });
      if (error.response) {
        throw new Error(
          error.response.status + ": " + error.response.data.error
        );
      } else {
        throw new Error(error.message || "unknown server error");
      }
    }
  }

  async put<T = any>(path: string, data: any, base = ""): Promise<T> {
    const url = `${base ? base : this.v2}${path}`;
    try {
      const r = await axios.put<ApiResponse>(url, data, {
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token") || "",
        },
      });
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log(`error in PUT request`, { [url]: error });
      console.error(error);
      if (error.response) {
        throw new Error(
          error.response.status + ": " + error.response.data.error
        );
      } else {
        throw new Error(error.message || "unknown server error");
      }
    }
  }

  async patch<T = any>(path: string, data: any, base = ""): Promise<T> {
    const url = `${base ? base : this.v2}${path}`;
    try {
      const r = await axios.patch<ApiResponse>(url, data, {
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token") || "",
        },
      });
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log(`error in PATCH request`, { [url]: error });
      console.error(error);
      if (error.response) {
        throw new Error(
          error.response.status + ": " + error.response.data.error
        );
      } else {
        throw new Error(error.message || "unknown server error");
      }
    }
  }

  async delete<T = any>(path: string, data?: any, base = ""): Promise<T> {
    const url = `${base ? base : this.v2}${path}`;
    try {
      const r = await axios.delete<ApiResponse>(url, {
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token") || "",
        },
        data,
      });
      console.log({ [url]: r });
      return r.data.data;
    } catch (error: any) {
      console.log(`error in DELETE request`, { [url]: error });
      console.error(error);
      if (error.response) {
        throw new Error(
          error.response.status + ": " + error.response.data.error
        );
      } else {
        throw new Error(error.message || "unknown server error");
      }
    }
  }

  async getAllFixtures(search: string) {
    let path = "/search/fixtures";
    if (search) path += `?s=${search}`;
    const r = await this.get(path);
    return r;
  }

  async getActiveFixtures() {
    const r = await this.get("/admin/active-fixtures");
    return r;
  }

  async updateFixtures(params: UpdateFixturesRequest) {
    const r = await this.put<Fixture[]>("/admin/fixtures", params);
    return r;
  }

  async getSingleFixture(id: string) {
    const r = await this.get<Fixture>(`/admin/fixtures/${id}`);
    return r;
  }

  async activateFixture(id: string) {
    const r = await this.get(`/fixtures/${id}/activate`, this.internal);
    return r;
  }

  async deactivateFixture(id: string) {
    const r = await this.get(`/fixtures/${id}/deactivate`, this.internal);
    return r;
  }

  async addRemoveFixtureContestId(id: string, contestTypeId: string) {
    const r = await this.post(`/admin/fixtures/${id}/contest-types`, {
      contestTypeId,
    });
    return r;
  }

  async getTeams(search: string) {
    let path = "/search/teams";
    if (search) path += `?s=${search}`;
    const r = await this.get<Team[]>(path);
    return r;
  }

  async getSingleTeam(id: string) {
    const r = await this.get<Team>(`/admin/teams/${id}`);
    return r;
  }

  async getPlayers(search: string): Promise<Player[]> {
    let path = "/search/players";
    if (search) path += `?s=${search}`;
    const r = await this.get(path);
    console.log({
      r,
    });
    //@ts-ignore
    return r;
  }

  async getSinglePlayer(id: string): Promise<Player> {
    const r = allPlayersJson.data;
    //@ts-ignore
    return r.find((p) => p.id === id);
  }

  async getAllKits(): Promise<Kit[]> {
    const r = await this.get(`/admin/kits`);
    return r;
  }

  getKits = async (q?: string | null) => {
    console.log("q: ", q);
    let path = "/admin/kits";
    if (q) path += `?s=${q}`;
    console.log(path);
    return await this.get<Kit[]>(path);
  };

  async getUsers() {
    let path = "/search/users";
    const r = await this.get<User[]>(path);
    return r;
  }

  async createKit(data: any) {
    const fileList: FileList = data.imageFile;
    delete data.imageFile;
    console.log({ kit: data });
    const r = await postWithFiles({
      url: `${this.v2}/admin/kits`,
      data,
      fileList,
      token: window.localStorage.getItem("token") || "",
    });

    console.log({ r });
    return r;
  }

  async updateKit(data: any) {
    delete data.imageFile;
    const r2 = await this.put(`/admin/kits/${data.id}`, data);
    console.log({ r2 });
    return r2;
  }

  async assignKitToFixture(params: {
    fixtureId: string;
    kitId: string;
    teamType: "home" | "away";
    kitType: "players" | "goalKeeper";
  }) {
    const r = await this.put<Fixture>("/admin/fixture/kit", params);
    console.log({
      r,
    });
    return r;
  }

  async assignKitToTeams(params: {
    toIds: string[];
    kitId: string;
    kitType: "gkKitId" | "outKitId";
  }) {
    const r = await this.put("/admin/team/kit", params);
    return r;
  }

  async deleteKitFromTeam(params: {
    fromIds: string[];
    kitId: string;
    kitType: "gkKitId" | "outKitId";
  }) {
    return this.delete<Team[]>("/admin/team/kit", params);
  }

  async getAllPrizeDistributions() {
    const r = await this.get("/admin/prize-distributions");
    return r;
  }

  async getAllTvChannels() {
    const r = await this.get("/admin/tv-channels");
    return r;
  }

  async searchPlayers(needle: string) {
    const r = await this.get(`/search/players`);
    console.log({
      r,
    });
    return (r.data || []) as Player[];
  }

  async searchFixtures(needle: string) {
    console.log({
      thisTT: this,
    });
    const r = await this.get(`/search/fixtures`);

    return (r.data || []) as Fixture[];
  }

  async updatePlayerNamesFixture({
    playerId,
    fixtureId,
    data,
  }: {
    playerId: string;
    fixtureId?: string;
    data: Partial<FixturePlayer>;
  }) {
    const r = await this.post<FixturePlayer>(
      `/fixtures/${fixtureId}/players/${playerId}`,
      data,
      this.internal
    );
    return r;
  }

  // async generateKitFixture({ playerId, fixtureId }: { playerId: string; fixtureId: string }) {
  //   const r = await this.get<Player>(`/fixtures/${fixtureId}/players/${playerId}/generateKit`, this.v1);
  //   return r;
  // }

  async generatePlayerKit({ playerId }: { playerId: string }) {
    const r = await this.get<void>(`/players/${playerId}/generateKit`, this.v1);
    return r;
  }

  // async getPlayerFixture({ playerId, fixtureId }: { playerId: string; fixtureId: string }) {
  //   const r = await this.get<Player>(`/fixtures/${fixtureId}/players/${playerId}`, this.v1);
  //   return r;
  // }

  async updatePlayerNames({
    playerId,
    data,
  }: {
    playerId: string;
    data: PartialPlayer;
  }) {
    const r = await this.put<Player>(`/admin/players/${playerId}`, data);
    console.log({
      r,
    });
    return r;
  }

  async getFixtureLineupPlayers(fixtureId: string) {
    //TODO: switch to v2 when ready
    // const r = await this.get<FixturePlayer[]>(`/admin/fixtures/${fixtureId}/players`);
    const r = await this.get<FixturePlayer[]>(
      `/fixtures/${fixtureId}/players`,
      this.internal
    );
    return r;
  }

  async getMe() {
    const me = await this.get<User>("/users/me", this.v1);
    return me;
  }

  async createContestType(data: ContestTypeT) {
    const r = await this.post<ContestTypeT>("/admin/contest-types", data);
    return r;
  }
  async updateContestType(id: string, data: ContestTypeT) {
    const r = await this.patch<ContestTypeT>(
      `/admin/contest-types/${id}`,
      data
    );
    return r;
  }
  async deleteContestType(id: string) {
    const r = await this.delete<ContestTypeT>(`/admin/contest-types/${id}`);
    return r;
  }
}

const fanslideClient = new FanslideClient();
export default fanslideClient;
