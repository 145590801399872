const Header = ({
  title,
  buttons,
}: {
  title?: string;
  buttons: JSX.Element[];
}) => {
  return (
    <div className="flex justify-between">
      <h1 className="text-3xl font-extrabold text-gray-900">{title}</h1>
      <div className="flex justify-end gap-4">
        {buttons.map((btn, i) => (
          <div key={"btn" + i}>{btn}</div>
        ))}
      </div>
    </div>
  );
};

export default Header;
