import React from "react";
import { ComponentChildren } from "../types/shared";
import { AllFixturesContextProvider } from "./FixturesContext";
import { AuthContextProvider } from "./AuthContext";

export const CMSContext = React.createContext({});

export const CMSContextProvider = ({ children }: ComponentChildren) => {
  return (
    <CMSContext.Provider value={{}}>
      <AuthContextProvider>{children}</AuthContextProvider>
    </CMSContext.Provider>
  );
};
