import React from "react";
import { ComponentChildren } from "../../types/shared";

function NiceBox({ children, className }: ComponentChildren) {
  return (
    <div className={`hidden sm:block mt-2 mb-8 ${className ? className : ""}`}>
      <div className=" ">{children}</div>
    </div>
  );
}

export default NiceBox;
