import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function PrimaryLink({ to, text }: { to: string; text: string }) {
  return (
    <Link
      to={to}
      type="button"
      className="inline-flex items-center px-2 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      {text}
    </Link>
  );
}

export function PrimaryButton({
  onClick,
  text,
  color = "indigo",
  disabled = false,
  className = "",
  type = "button",
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
  color?: string;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit";
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(
        `inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 whitespace-nowrap mx-1`,
        disabled && "bg-gray-600 hover:bg-gray-700",
        className
      )}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
