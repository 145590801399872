import React from "react";
import { ApiResponse } from "../types/api";

export type ServerFeedback = {
  type: "error" | "success";
  message: string;
};

function useServerFeedback() {
  const [feedback, setFeedbackState] = React.useState<ServerFeedback>({} as ServerFeedback);

  const setFeedback = (obj: ServerFeedback | ApiResponse) => {
    if ((obj as any).statusCode) {
      // then it is a an api response.
      if ((obj as any).error) {
        setFeedbackState({
          type: "error",
          message: (obj as any).error,
        });
      } else {
        // successful api call
        let message = `success, `;
        if (Array.isArray((obj as any).data)) {
          message += `${(obj as any).data.length} items affected.`;
        } else if (typeof (obj as any).data === "object") {
          message += `1 item affected.`;
        } else if (typeof (obj as any).data === "string") {
          message += (obj as any).data;
        }

        setFeedbackState({
          type: "success",
          message: message,
        });
      }
    } else {
      // it is feedback object.
      setFeedbackState(obj as any);
    }
  };

  return {
    feedback,
    setFeedback,
  };
}

export default useServerFeedback;
