import { AscDesc } from "../hooks/useMap";
import { ISOTimeStamp } from "../types/fixture";
import moment from "moment";
export function joinClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function flipSortDirection(currentDirection: AscDesc) {
  if (currentDirection === "asc") return "desc";
  else return "asc";
}

export function displayDate(iso: ISOTimeStamp | string): string {
  // const d = new Date(iso);
  // //@ts-ignore
  // if (d === "Invalid Date") return "";
  // const mins = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  // const hrs = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  // return hrs + ":" + mins + ", " + d.toDateString();
  return moment(iso).format("ddd[,] MMM Do YY [at] HH:mm");
}

export async function sleep(ms: number) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}
