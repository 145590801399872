import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import logoBlack from "../../assets/images/fanslide_black.png";
import DisplayServerFeedback from "./DisplayServerFeedback";
import { twMerge } from "tailwind-merge";

export default function SignIn() {
  const [err, setErr] = useState<string>("");
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const authorizer = useContext(AuthContext);

  const [isValid, setIsValid] = useState(false);

  const formOnChange = (e: any) => {
    e.preventDefault();
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsValid(
      !!formState.email &&
        !!formState.password &&
        !!formState.otp &&
        formState.otp.length === 6
    );
  }, [formState]);

  const loginFunc = async (e: any) => {
    e.preventDefault();
    setErr("");
    const res = await authorizer.login(
      formState.email,
      formState.password,
      formState.otp
    );
    if (res.error) {
      setErr(res.error as string);
      return;
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <img className="mx-auto h-12 w-auto" src={logoBlack} alt="Workflow" />

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        {err && (
          <DisplayServerFeedback feedback={{ type: "error", message: err }} />
        )}
        {authorizer.loggedOut && (
          <DisplayServerFeedback
            feedback={{
              type: "success",
              message: "You have successfully logged out",
            }}
          />
        )}
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mt-4">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="email"
                  value={formState.email}
                  onChange={formOnChange}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="password"
                  value={formState.password}
                  onChange={formOnChange}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-gray-700"
              >
                One Time Password
              </label>
              <div className="mt-1">
                <input
                  id="otp"
                  type="otp"
                  autoComplete="otp"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="otp"
                  value={formState.otp}
                  onChange={formOnChange}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={twMerge(
                  "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                  isValid
                    ? "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                    : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500"
                )}
                onClick={loginFunc}
                disabled={!isValid}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
