import { PencilIcon } from "@heroicons/react/solid";
import { getFixtureAwayAndHomeTeams } from "../../../../app/fixtures";
import useGetData from "../../../../hooks/useGetData";
import { Fixture } from "../../../../types/fixture";
import { Kit } from "../../../../types/kit";
import emptyKit from "../../../../assets/images/emptyKit.png";
import Loading from "../../../../components/Main/Loading";

const KitCard = ({
  team,
  type,
  fixture,
  onClick,
}: {
  team: "home" | "away";
  type: "Out" | "Gk";
  fixture?: Fixture;
  onClick: (team: "home" | "away", type: "Out" | "Gk") => void;
}) => {
  const { homeTeam, awayTeam } = getFixtureAwayAndHomeTeams(fixture);
  const teamName = team === "home" ? homeTeam?.shortName : awayTeam?.shortName;
  const kitId = fixture?.[`${team}${type}KitId`];
  const {
    isLoading,
    error,
    data: kits,
  } = useGetData<Kit[]>("/admin/kits", undefined, { enabled: !!kitId });
  const kit = kits?.find((k) => k.id === kitId);
  if (isLoading) return <Loading />;
  return (
    <div className="flex flex-col text-center bg-white rounded-lg shadow-md divide-y divide-gray-200">
      <div className="flex-1 flex flex-col p-8">
        <img
          className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
          src={kit?.url || emptyKit}
          alt=""
        />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">{teamName}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dd className="text-gray-500 text-sm">
            {type === "Gk" && "Goalkeeper"}
            {type === "Out" && "Outfield"}
          </dd>
        </dl>
      </div>
      <button
        onClick={() => onClick(team, type)}
        className="-mt-px flex divide-x divide-gray-200"
      >
        <div className="w-0 flex-1 flex">
          <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
            <PencilIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            <span className="ml-3">Update</span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default KitCard;
