import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import fanslideClient from "../app/api/FanslideClient";
import { sleep } from "../app/helpers";

const useGetData = <T = any>(
  endpoint: string,
  base?: string,
  options?: { enabled: boolean }
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, error, data, refetch } = useQuery(
    [endpoint, searchParams.get("s")],
    async () => {
      let path = endpoint;
      if (searchParams.get("s")) path += "?s=" + searchParams.get("s");
      console.log("Fetch: ", (base || fanslideClient.v2) + path);

      return fanslideClient.get<T>(path, base);
    },
    options
  );

  return { isLoading, error: error as Error, data };
};

export default useGetData;
