import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  index?: number;
  onClick?: () => void;
};

const Tr = ({ children, index, onClick }: Props) => {
  return (
    <tr
      className={twMerge(
        onClick && "cursor-pointer hover:opacity-75",
        !index || index % 2 === 0 ? "" : "bg-gray-50"
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

export default Tr;
