export const FANSLIDE_LOGO_URL = "https://fanslide.com/assets/images/fanslide-logo-white.svg?v=2";
export const ModalCustomStyles = (obj: any = {}) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: obj.width || "75%",
    padding: 0,
    margin: 0,
    height: obj.height,
  },
});
