import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import fanslideClient from "../app/api/FanslideClient";
import { User } from "../types/user";

type UsersContextType = {
  users: User[];
  searchNeedle: string;
  setSearchNeedle: (s: string) => void;
  loading: boolean;
  view: string;
  cancelForm: () => void;
  showSingleSelectedUser: (id: string) => Promise<void>;
  selectedSingleUser: User;
};
export const UsersContext = createContext({} as UsersContextType);

export function UsersContextProvider({ children }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchNeedle, setSearchNeedle] = useState("");
  const query = useQuery<User[], Error>(["users", searchParams.get("s")], () =>
    fanslideClient.getUsers()
  );
  const [selectedSingleUser, setSelectedSingleUser] = useState<User>(
    {} as User
  );

  const cancelForm = () => {
    const obj: any = {
      view: "all",
    };

    if (searchParams.get("s")) {
      obj["s"] = searchParams.get("s");
    }

    setSearchParams(obj);
  };

  const showSingleSelectedUser = async (userId: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      view: "single",
      id: userId,
    });
    const u = (query.data || []).find((p) => p.id === userId) || ({} as User);
    setSelectedSingleUser(u);
  };

  return (
    <UsersContext.Provider
      value={{
        users:
          query.data?.filter(
            (u) =>
              u.firstName?.toLowerCase().includes(searchNeedle.toLowerCase()) ||
              u.lastName?.toLowerCase().includes(searchNeedle.toLowerCase()) ||
              u.fanslideUsername
                ?.toLowerCase()
                .includes(searchNeedle.toLowerCase())
          ) || [],
        loading: query.isLoading,
        searchNeedle,
        setSearchNeedle,
        view: searchParams.get("view") || "all",
        cancelForm,
        showSingleSelectedUser,
        selectedSingleUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export const useUsersContext = () => useContext(UsersContext);
